<template>
<!-- 基本信息 -->
  <el-descriptions title="基本信息">
    <el-descriptions-item label="公司全称">{{info.departmentName}}</el-descriptions-item>
    <el-descriptions-item label="公司代码">{{info.departmentDesc}}</el-descriptions-item>
  </el-descriptions>
</template>


<script>
export default {
  data(){
    return{}
  },
  props:['info']
}
</script>