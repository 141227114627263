<template>
  <el-dialog :title="isAdd ? '新增类型' : '编辑类型'" :visible.sync="viewVisible" width="650px" :before-close="handleClose" :close-on-click-modal="false" center>
    <div class="from-body">
      <el-form :model="ruleForm" ref="ruleForm" :rules="rules" label-width="auto" class="demo-ruleForm">
        <el-form-item label="类型名称" prop="name">
          <el-input v-model="ruleForm.name"/>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button :style="{ color: buttonTextColor }" @click="handleClose">取 消</el-button>
      <el-button :style="{ color: buttonTextColor }" type="primary" @click="submit">确 定</el-button>
    </span>
  </el-dialog>
</template>


<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      ruleForm: {
        id: null,
        name: ""
      },
      rules: {
        name: [
          {
            required: true,
            message: "请填写类型名称",
            trigger: "blur",
          },
        ]
      },
    };
  },
  watch: {
    viewVisible(newVal) {
      if (newVal) {
        //新增
        if (this.isAdd) {
            this.ruleForm.id = null;
            this.ruleForm.name = "";
        } else {
          //修改
          this.ruleForm.id = this.currentData.id;
          this.ruleForm.name = this.currentData.name;
        }
      }
    },
  },
  computed: {
    ...mapState(["buttonTextColor"]),
  },
  props: ["viewVisible", "currentData", "isAdd"],
  methods: {
    handleClose() {
      this.ruleForm = {
        id: null,
        name: ""
      };
      this.$refs["ruleForm"].resetFields();
      this.ruleForm.id = "";
      this.$emit("viewClose");
    },
    //提交
    submit() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          if (this.isAdd) {
            this.request.categoryAdd(this.ruleForm).then((res) => {
              if (res.code==0) {
                this.$message.success("新增成功");
                this.$emit("categoryList");
                this.handleClose();
              }
            });
          } else {
            this.request.categoryEdit(this.ruleForm).then((res) => {
              if (res.code==0) {
                this.$message.success("编辑成功");
                this.$emit("categoryList");
                this.handleClose();
              }
            });
          }
        }
      });
    },
  },
};
</script>


<style scoped>
.from-body {
  padding: 0 60px;
  box-sizing: border-box;
}
</style>