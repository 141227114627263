<template>
  <el-date-picker
    v-model="value"
    type="datetimerange"
    range-separator="至"
    start-placeholder="开始日期"
    end-placeholder="结束日期"
    @change="change"
    style="width: 356px"
    :disabled="disabled?disabled:false"
  >
  </el-date-picker>
</template>


<script>
import { DateToStr } from "../../utils/util/util";
export default {
  data() {
    return {
      value: [],
    };
  },
  watch: {
    start() {
      this.value = [new Date(this.start), new Date(this.stop)];
    },
    stop() {
      this.value = [new Date(this.start), new Date(this.stop)];
    },
    value(newVal) {
      if (newVal == null) {
        this.value = [];
      }
    },
  },
  mounted() {
     this.value = [this.start?new Date(this.start):'', this.stop?new Date(this.stop):''];
  },
  props: ["start", "stop",'disabled'],
  methods: {
    change() {
      let that = this;
      if (!that.value) {
        that.$emit("update:start", '');
        that.$emit("update:stop", '');
        that.$emit("change", '');
      } else {
        that.$emit("update:start", DateToStr(that.value[0]));
        that.$emit("update:stop", DateToStr(that.value[1]));
        that.$emit("change", that.value);
      }
    },
  },
};
</script>


<style scoped>
</style>