<template>
  <div class="form">
    <!-- 权限列表 -->
    <el-drawer :title="title" :before-close="jurisdictionClose" :visible.sync="menuVisible" direction="rtl" custom-class="demo-drawer" ref="drawer">
      <div class="demo-drawer__content">
        <div class="jurisdiction-content">
          <el-tree 
            ref="tree" 
            :data="menuData" 
            node-key="id" 
            :check-strictly="true"
            show-checkbox
            default-expand-all
            :props="defaultProps"
            :default-checked-keys="checkedKeys"/>
        </div>
        <div class="demo-drawer__footer">
          <el-button :style="{ color: buttonTextColor }" @click="jurisdictionClose">关闭</el-button>
          <el-button :style="{ color: buttonTextColor }" type="primary" @click="submitSelect">确定</el-button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      menuData: [],
      checkedKeys: [],
      iStrictly: false, 
      jurisdiction: true,
      title: "权限分配",
      menuIds: [], 
      defaultProps: {
        children: "children",
        label: "menuName",
      },
    };
  },
  components: {

  },
  watch: {
    menuVisible(newVal) {
      if (newVal) {
        this.title="权限分配（"+this.shortName+"）"
        this.listTenantMenus();
      }
    },
  },
  computed: {
    ...mapState(["buttonTextColor"]),
  },
  props: ["tenantId","shortName","menuVisible"],
  mounted() {},
  methods: {
    listTenantMenus() {
      var params={};
      params['tenantId']=this.tenantId;
      this.request.listTenantMenus(params).then((res) => {
        this.menuData = res.data.menuList;
        this.checkedKeys = res.data.menuIds;
      });
    },
    //关闭权限弹窗
    jurisdictionClose() {
      this.jurisdiction = false;
      this.$emit("menuClose");
    },
    //提交权限选择
    submitSelect() {
      let arrayId = this.$refs.tree.getCheckedKeys().concat(this.$refs.tree.getHalfCheckedKeys());
      this.menuIds = arrayId;
      var params={};
      params['tenantId']=this.tenantId;
      params['menuIds']=this.menuIds;
      this.request.saveTenantMenus(params).then((res) => {
        if (res.code==0) {
          this.$message.success("操作成功");
          this.jurisdictionClose();
        }
      });
    }
  },
};
</script>

<style scoped>
.form {
  padding: 30px;
  box-sizing: border-box;
}
.line-form {
  width: 100%;
  display: flex;
}
.demo-drawer__content {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  padding-top: 0;
}
.jurisdiction-content {
  flex: 1;
  margin-top: 20px;
}
.demo-drawer__footer {
  padding: 20px 0;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}
</style>