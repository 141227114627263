<template>
  <el-dialog :title="isAdd ? '新增解码协议' : '编辑解码协议'" :visible.sync="viewVisible" width="650px" :before-close="handleClose" :close-on-click-modal="false" center>
    <div class="from-body">
      <el-form :model="ruleForm" ref="ruleForm" :rules="rules" label-width="auto">
        <el-form-item label="协议名称" prop="name">
          <el-input v-model="ruleForm.name" @input="onInput"/>
        </el-form-item>
        <el-form-item label="解码协议" prop="codec">
          <el-input v-model="ruleForm.codec" @input="onInput"/>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button :style="{ color: buttonTextColor }" @click="handleClose">取 消</el-button>
      <el-button :style="{ color: buttonTextColor }" type="primary" @click="submit">确 定</el-button>
    </span>
  </el-dialog>
</template>


<script>

import { mapState } from "vuex";
export default {
  data() {
    return {
      ruleForm: {
        id: null,
        name: null,
        codec: ""
      },
      rules: {
        codec: [
          {
            required: true,
            message: "请填写解码协议",
            trigger: "blur",
          },
        ],
        name: [
          {
            required: true,
            message: "请指定协议名称",
            trigger: "blur",
          },
        ]
      },
    };
  },
  watch: {
    viewVisible(newVal) {
      if (newVal) {
        if (this.isAdd) {
            //新增
            this.ruleForm.id = null;
            this.ruleForm.name = null;
            this.ruleForm.codec = null;
        } else {
          //修改
          this.ruleForm.id = this.currentData.id;
          this.ruleForm.name = this.currentData.name;
          this.ruleForm.codec = this.currentData.codec;
        }
      }
    },
  },
  components: {},
  computed: {
    ...mapState(["buttonTextColor"]),
  },
  props: ["viewVisible", "currentData", "isAdd"],
  methods: {
    onInput() {
      //input输入框无法编辑
      this.$forceUpdate();
    },
    //处理关闭
    handleClose() {
      this.ruleForm = {
        id: null,
        name: "",
        codec: ""
      };
      this.$refs["ruleForm"].resetFields();
      this.$emit("viewClose");
    },
    //提交保存
    submit() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          if (this.isAdd) {
            this.request.protocolAdd(this.ruleForm).then((res) => {
              if (res.code==0) {
                this.$message.success("新增成功");
                this.$emit("protocolList");
                this.handleClose();
              }
            });
          } else {
            this.request.protocolEdit(this.ruleForm).then((res) => {
              if (res.code==0) {
                this.$message.success("编辑成功");
                this.$emit("protocolList");
                this.handleClose();
              }
            });
          }
        }
      });
    },
  },
};
</script>


<style scoped>
.from-body {
  padding: 0 60px;
  box-sizing: border-box;
}
</style>