<template>
  <!-- 部门管理 -->
  <div class="dilu-page">
    <div class="depart-view">
      <div class="depart-view-left">
        <div class="operation-div">
          <el-button
            :style="{ color: buttonTextColor }"
            icon="el-icon-plus"
            type="primary"
            @click="add"
            >新增部门</el-button
          >
          <el-button
            :style="{ color: buttonTextColor }"
            icon="el-icon-delete"
            @click="remove"
            >删除</el-button
          >
        </div>
        <div class="tree">
          <el-tree ref="tree" :data="dataList" show-checkbox node-key="id" default-expand-all :props="defaultProps" check-strictly @node-click="treeClick"/>
        </div>
      </div>
      <div class="depart-view-right">
        <div class="page-right">
          <el-tabs v-model="activeName">
            <el-tab-pane label="基本信息" name="first">
              <DepartmentInfo :info="info" />
            </el-tab-pane>
            <el-tab-pane label="人员信息" name="second">
              <DepartmentPersonnel :activeName="activeName" :tableData="tableData" :total="total" :pageSize="params.pageSize" @currentChange="currentChange"/>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>

    <DepartMentAddModule :departVisible="departVisible" @departClose="departClose" @departmentManagementListAll="departmentManagementListAll"/>

  </div>
</template>


<script>
import DepartmentInfo from "./module/departmentInfo.vue";
import DepartmentPersonnel from "./module/departmentPersonnel.vue";
import DepartMentAddModule from "./module/departMentAddModule.vue";
import { mapState } from "vuex";
export default {
  data() {
    return {
      departVisible: false,
      params: {
        pageSize: 10,
      },
      info: {},
      tableData: [],
      total: 0,
      activeName: "first",
      dataList: [],
      defaultProps: {
        children: "departmentList",
        label: "departmentName",
      },
    };
  },
  computed: {
    ...mapState(["buttonTextColor"]),
  },
  components: {
    DepartmentInfo,
    DepartmentPersonnel,
    DepartMentAddModule,
  },
  mounted() {
    this.departmentManagementListAll();
    this.accountManagementList();
  },
  methods: {
    //删除部门
    remove() {
      let arrayId = this.$refs.tree.getCheckedKeys();
      if (arrayId.length < 1) {
        this.$message.error("请选择要删除的部门");
      } else {
        this.$confirm("确定删除选中部门？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.request.departmentManagementDelete({ ids: arrayId }).then(() => {
            this.departmentManagementListAll();
            this.$message({
              type: "success",
              message: "删除成功",
            });
          });
        });
      }
    },
    add() {
      this.departVisible = true;
    },
    departClose() {
      this.departVisible = false;
    },
    //翻页
    currentChange(e) {
      this.params.pageNum = e;
      this.accountManagementList();
    },
    //点击节点触发
    treeClick(e) {
      if (e.id < 0) {
        this.departmentManagementListAll();
        this.accountManagementList();
      } else {
        this.params.departmentId = e.id;
        this.departmentManagementInfo(e.id);
        this.accountManagementList();
      }
    },
    //账号管理-列表（分页）
    accountManagementList() {
      this.request.accountManagementList(this.params).then((res) => {
        this.tableData = res.data;
        this.total = res.dataMaxCount;
      });
    },
    //部门管理-查询所有部门
    departmentManagementListAll() {
      this.request.departmentManagementListAll().then((res) => {
        this.dataList = [res.data];
        this.info = {
          departmentName: res.data.departmentName,
          departmentDesc: res.data.platformRemark,
        };
      });
    },
    //部门管理-根据id查询
    departmentManagementInfo(id) {
      let str = "?id=" + id;
      this.request.departmentManagementInfo(str).then((res) => {
        this.info = res.data;
      });
    },
  },
};
</script>


<style scoped>
.page-row {
  height: 100%;
  /* margin: 0 !important; */
}

.page-col {
  border-right: 1px solid #eee;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.page-col:last-child {
  border: none;
}
.tree {
  flex: 1;
  overflow: auto;
}
.page-right {
  height: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}

.depart-view {
  width: 100%;
  display: flex;
  height: 100%;
}
.depart-view-left {
  width: 260px;
  padding-right: 10px;
  box-sizing: border-box;
  flex-shrink: 0;
}
.depart-view-right {
  flex: 1;
  padding-left: 10px;
  margin-left: 10px;
  box-sizing: border-box;
  border-left: 1px solid #d7ddeb;
}
</style>