<template>
  <div class="dilu-page">
      <div class="form-body">
        <el-form :model="ruleForm" ref="ruleForm" :rules="rules" label-width="auto" class="demo-ruleForm">
          <el-form-item label="公司全称" prop="fullName" style="width:500px;">
            <el-input v-model="ruleForm.fullName" clearable/>
          </el-form-item>
          <el-form-item label="公司简称" prop="shortName" style="width:500px;">
            <el-input v-model="ruleForm.shortName" clearable/>
          </el-form-item>
          <el-form-item label="平台名称" prop="platformName" style="width:500px;">
            <el-input v-model="ruleForm.platformName" clearable/>
          </el-form-item>
          <el-form-item label="租户域名" prop="platformDomain" style="width:500px;">
            <el-input v-model="ruleForm.platformDomain" clearable/>
          </el-form-item>
          <el-form-item label="所在区域">
            <el-select v-model="ruleForm.region" style="width:430px;" clearable>
              <el-option label="中国区" value="cn"/>
              <el-option label="欧洲区" value="eur"/>
            </el-select>
          </el-form-item>
          <el-form-item label="所属行业">
            <el-select v-model="ruleForm.industry" style="width:430px;" clearable>
              <el-option label="暖通" value="100"/>
              <el-option label="光伏" value="150"/>
              <el-option label="储能" value="200"/>
              <el-option label="其他" value="250"/>
            </el-select>
          </el-form-item>
          <el-form-item label="租户类型">
            <el-select v-model="ruleForm.tenantType" style="width:430px;" clearable>
              <el-option label="工程公司" value="100"/>
              <el-option label="热力公司" value="150"/>
              <el-option label="能源公司" value="200"/>
              <el-option label="其他" value="250"/>
            </el-select>
          </el-form-item>
          <el-form-item label="所在国家">
            <el-select v-model="ruleForm.country" style="width:430px;">
              <el-option v-for="(item, index) in countryList" :key="index" :label="item.name" :value="item.name"/>
            </el-select>
          </el-form-item>
          <el-form-item label="所在省份">
            <el-select v-model="ruleForm.province" style="width:430px;" @change="provinceChange" filterable clearable>
              <el-option v-for="(item, index) in provinceList" :key="index" :label="item.name" :value="item.name"/>
            </el-select>
          </el-form-item>
          <el-form-item label="所在城市">
            <el-select v-model="ruleForm.city" style="width:430px;" @change="cityChange" filterable clearable>
              <el-option v-for="(item, index) in cityList" :key="index" :label="item.name" :value="item.name"/>
            </el-select>
          </el-form-item>
          <el-form-item label="所在区县">
            <el-select v-model="ruleForm.area" style="width:430px;" filterable clearable>
              <el-option v-for="(item, index) in areaList" :key="index" :label="item.name" :value="item.name"/>
            </el-select>
          </el-form-item>
          <el-form-item label="详细地址" prop="address" style="width:500px;">
            <el-input v-model="ruleForm.address" clearable/>
          </el-form-item>
          <el-form-item label="客户姓名" prop="contactPerson" style="width:500px;">
            <el-input v-model="ruleForm.contactPerson" clearable/>
          </el-form-item>
          <el-form-item label="联系电话" prop="contactPhone" style="width:500px;">
            <el-input v-model="ruleForm.contactPhone" clearable/>
          </el-form-item>
          <el-form-item label="联系邮箱" prop="contactEmail" style="width:500px;">
            <el-input v-model="ruleForm.contactEmail" clearable/>
          </el-form-item>
          <el-form-item label="定位经度" prop="locationLng" style="width:500px;">
            <el-input v-model="ruleForm.locationLng" clearable/>
          </el-form-item>
          <el-form-item label="定位纬度" prop="locationLat" style="width:500px;">
            <el-input v-model="ruleForm.locationLat" clearable/>
          </el-form-item>
          <el-form-item label="开放接口">
            <el-select v-model="ruleForm.openApi" style="width:430px;" clearable>
              <el-option label="开启" :value=10 />
              <el-option label="关闭" :value=20 />
            </el-select>
          </el-form-item>
          <el-form-item label="授权账号" prop="openAccount" style="width:500px;">
            <el-input v-model="ruleForm.openAccount" clearable/>
          </el-form-item>
          <el-form-item label="授权密钥" prop="openToken" style="width:500px;">
            <el-input v-model="ruleForm.openToken" clearable/>
          </el-form-item>
          <el-form-item label="Influx服务器" prop="influxUrl" style="width:500px;">
            <el-input v-model="ruleForm.influxUrl" clearable/>
          </el-form-item>
          <el-form-item label="Influx组织" prop="influxOrg" style="width:500px;">
            <el-input v-model="ruleForm.influxOrg" clearable/>
          </el-form-item>
          <el-form-item label="Influx令牌" prop="influxToken" style="width:500px;">
            <el-input v-model="ruleForm.influxToken" clearable/>
          </el-form-item>
          <el-form-item label="APP代码" prop="appCode" style="width:500px;">
            <el-input v-model="ruleForm.appCode" clearable/>
          </el-form-item>
          <el-form-item label="营业执照" prop="imageLicense">
            <DiluUploadImg accept=".png;.jpg"  :limit="1" :fileList="licenseList"  @remove="removeLicense" @success="successLicense"/>
          </el-form-item>
          <el-form-item label="登录图片" prop="imageLogin">
            <DiluUploadImg accept=".png;.jpg"  :limit="1" :fileList="loginList"  @remove="removeLogin" @success="successLogin"/>
          </el-form-item>
          <el-form-item label="后台图标" prop="imageLogo">
            <DiluUploadImg accept=".png;.jpg"  :limit="1" :fileList="logoList"  @remove="removeLogo" @success="successLogo"/>
          </el-form-item>
          <el-form-item label="客户备注" prop="remark" style="width:500px;">
            <el-input type="textarea" rows="4" v-model="ruleForm.remark" />
          </el-form-item>
        </el-form>
      </div>
      <span v-if="isAdd=='1' || isAdd=='2'" slot="footer" class="dialog-footer">
        <el-button :style="{ color: buttonTextColor }" @click="handleClose">关 闭</el-button>
        <el-button :style="{ color: buttonTextColor }" style="margin-left:50px;" type="primary" @click="submit">确 定</el-button>
      </span>
  </div>
</template>


<script>
import DiluUploadImg from "@/components/public/dilu-uploadImg.vue";
import { mapState } from "vuex";
export default {
  data() {
    return {
      title: "新增租户",
      ruleForm: {
        id: null,           //自增ID
        tenantId: null,     //租户ID
        fullName: "",       //公司全称
        shortName: "",      //公司简称
        region: "cn",       //所属区域（cn：中国区，eur：欧洲区）
        platformName: "",   //平台名称
        platformDomain: "", //平台域名
        country: "中国",    //国家
        province: "",       //省份
        city: "",           //城市
        area: "",           //区县
        address: "",        //详细地址
        industry: "250",    //所属行业
        tenantType: "250",  //租户类型
        contactPerson: "",  //客户姓名
        contactPhone: "",   //联系电话
        contactEmail: "",   //联系邮箱
        locationLng: "",    //定位经度
        locationLat: "",    //定位纬度
        status: 10,         //租户状态（10：正常，20：已过期）
        expireDate: "",     //过期日期
        openApi: 20,        //开放接口
        openAccount: "",    //授权账号
        openToken: "",      //授权密钥
        appCode: "",        //APP代码
        imageLicense: "",   //营业执照
        imageLogin: "",     //登录页图片
        imageLogo: "",      //后台图标
        influxUrl: "",      //Influx服务器
        influxOrg: "",      //Influx组织
        influxToken: "",    //Influx令牌
        remark: "",         //租户备注
      },
      province: "",     //当前省份    
      city: "",         //当前城市
      tenantList: [],   //平台客户列表
      licenseList: [],  //营业执照
      loginList: [],    //登录图片
      logoList: [],     //后台图标
      provinceList: [], //省份列表
      cityList: [],     //城市列表
      areaList: [],     //区县列表
      countryList: [{"name":"中国"}], 
      isAdd: 1,
      currentData: {},
      rules: {},
    };
  },
  components: {
    DiluUploadImg
  },
  computed: {
    ...mapState(["buttonTextColor"]),
  },
  mounted() {
    this.isAdd=this.$route.query.isAdd;
    this.currentData=this.$route.query.currentData;
    this.getProvince();
    this.showInit();
  },
  methods: {
    showInit() {
      if (this.isAdd == 1) {
        this.ruleForm.title = "新增租户";
        this.ruleForm.id=null;           //自增ID
        this.ruleForm.tenantId=null;     //租户ID
        this.ruleForm.fullName="";       //公司全称
        this.ruleForm.shortName="";      //公司简称
        this.ruleForm.region="cn";       //所属区域（cn：中国区，eur：欧洲区）
        this.ruleForm.platformName="";   //平台名称
        this.ruleForm.platformDomain=""; //平台域名
        this.ruleForm.country="中国";     //国家
        this.ruleForm.province="";       //省份
        this.ruleForm.city="";           //城市
        this.ruleForm.area="";           //区县
        this.ruleForm.address="";        //详细地址
        this.ruleForm.industry="250";    //所属行业
        this.ruleForm.tenantType="250";  //租户类型
        this.ruleForm.contactPerson="";  //客户姓名
        this.ruleForm.contactPhone="";   //联系电话
        this.ruleForm.contactEmail="";   //联系邮箱
        this.ruleForm.locationLng="";    //定位经度
        this.ruleForm.locationLat="";    //定位纬度
        this.ruleForm.status=10;         //租户状态（10：正常，20：已过期）
        this.ruleForm.expireDate="";     //过期日期
        this.ruleForm.openApi=20;        //开放接口
        this.ruleForm.openAccount="";    //授权账号
        this.ruleForm.openToken="";      //授权密钥
        this.ruleForm.appCode="";        //APP代码
        this.ruleForm.imageLicense="";   //营业执照
        this.ruleForm.imageLogin="";     //登录页图片
        this.ruleForm.imageLogo="";      //后台图标
        this.ruleForm.influxUrl="";      //Influx服务器
        this.ruleForm.influxOrg="";      //Influx组织
        this.ruleForm.influxToken="";    //Influx令牌
        this.ruleForm.remark="";         //租户备注
      } else if (this.isAdd == 2 || this.isAdd == 3) {
        if (this.isAdd == 2) {
          this.title = "编辑租户";
        } else {
          this.title = "查看租户";
        }
        this.ruleForm.id=this.currentData.id;                            //自增ID
        this.ruleForm.tenantId=this.currentData.tenantId;                //租户ID
        this.ruleForm.fullName=this.currentData.fullName;                //公司全称
        this.ruleForm.shortName=this.currentData.shortName;              //公司简称
        this.ruleForm.region=this.currentData.region;                    //所属区域（cn：中国区，eur：欧洲区）
        this.ruleForm.platformName=this.currentData.platformName;        //平台名称
        this.ruleForm.platformDomain=this.currentData.platformDomain;    //平台域名
        this.ruleForm.country=this.currentData.country;                  //国家
        this.ruleForm.province=this.currentData.province;                //省份
        this.ruleForm.city=this.currentData.city;                        //城市
        this.ruleForm.area=this.currentData.area;                        //区县
        this.ruleForm.address=this.currentData.address;                                   //详细地址
        this.ruleForm.industry=this.currentData.industry;                //所属行业
        this.ruleForm.tenantType=this.currentData.tenantType;            //租户类型
        this.ruleForm.contactPerson=this.currentData.contactPerson;      //客户姓名
        this.ruleForm.contactPhone=this.currentData.contactPhone;        //联系电话
        this.ruleForm.contactEmail=this.currentData.contactEmail;        //联系邮箱
        this.ruleForm.locationLng=this.currentData.locationLng;          //定位经度
        this.ruleForm.locationLat=this.currentData.locationLat;          //定位纬度
        this.ruleForm.status=this.currentData.status;                    //租户状态（10：正常，20：已过期）
        this.ruleForm.expireDate=this.currentData.expireDate;            //过期日期
        this.ruleForm.openApi=this.currentData.openApi;                  //开放接口
        this.ruleForm.openAccount=this.currentData.openAccount;          //授权账号
        this.ruleForm.openToken=this.currentData.openToken;              //授权密钥
        this.ruleForm.appCode=this.currentData.appCode;                  //APP代码
        this.ruleForm.imageLicense=this.currentData.imageLicense;        //营业执照
        this.ruleForm.imageLogin=this.currentData.imageLogin;            //登录图片
        this.ruleForm.imageLogo=this.currentData.imageLogo;              //后台图标
        this.ruleForm.influxUrl=this.currentData.influxUrl;              //Influx服务器
        this.ruleForm.influxOrg=this.currentData.influxOrg;              //Influx组织
        this.ruleForm.influxToken=this.currentData.influxToken;          //Influx令牌
        this.ruleForm.remark=this.currentData.remark;                    //租户备注
        //营业执照
        let license = [];
        license.push({
          url: this.ruleForm.imageLicense,
          name: "license"
        });
        this.licenseList = license;
        //登录图片
        let login = [];
        login.push({
          url: this.ruleForm.imageLogin,
          name: "login"
        });
        this.loginList = login;
        //后台图标
        let logo = [];
        logo.push({
          url: this.ruleForm.imageLogo,
          name: "logo"
        });
        this.logoList = logo;
      } 
    },
    getProvince() {
      this.request.getProvince().then((res) => {
        this.provinceList = res.data;
      });
    },
    getCity() {
      let params={};
      params['province']=this.province;
      this.request.getCity(params).then((res) => {
        this.cityList = res.data;
      });
    },
    getArea() {
      let params={};
      params['city']=this.city;
      this.request.getArea(params).then((res) => {
        this.areaList = res.data;
      });
    },
    handleClose() {
      this.$refs["ruleForm"].resetFields();
      this.licenseList = [];
      this.loginList = [];
      this.logoList = [];
      this.$router.go(-1);
    },
    removeLicense(e) {
      const i = this.licenseList.findIndex((x) => x.name == e);
      this.licenseList.splice(i, 1);
      this.ruleForm.imageLicense = null;
    },
    successLicense(e) {
      this.ruleForm.imageLicense = e.url;
      this.licenseList = e.fileList;
    },
    removeLogin(e) {
      const i = this.loginList.findIndex((x) => x.name == e);
      this.loginList.splice(i, 1);
      this.ruleForm.imageLogin = null;
    },
    successLogin(e) {
      this.ruleForm.imageLogin = e.url;
      this.loginList = e.fileList;
    },
    removeLogo(e) {
      const i = this.logoList.findIndex((x) => x.name == e);
      this.logoList.splice(i, 1);
      this.ruleForm.imageLogo = null;
    },
    successLogo(e) {
      this.ruleForm.imageLogo = e.url;
      this.logoList = e.fileList;
    },
    provinceChange(value) {
      for (let item of this.provinceList) {
        if (item['name']==value) {
          this.province=item['id'];
        }
      }
      this.getCity();
    },
    cityChange(value) {
      for (let item of this.cityList) {
        if (item['name']==value) {
          this.city=item['id'];
        }
      }
      this.getArea();
    },
    //确定
    submit() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          if (this.isAdd == 1) {
            this.request.tenantAdd(this.ruleForm).then((res) => {
              if (res.code==0) {
                this.$message.success("新增成功");
                setTimeout(() => {
                  this.$emit("queryList");
                  this.handleClose();
                }, 1000);
              }
            });
          } else {
            this.request.tenantEdit(this.ruleForm).then((res) => {
              if (res.code==0) {
                this.$message.success("编辑成功");
                setTimeout(() => {
                  this.$emit("queryList");
                  this.handleClose();
                }, 1000);
              }
            });
          }
        }
      });
    },
  },
};
</script>


<style scoped>
.form-body {
  padding: 0 60px;
  box-sizing: border-box;
}
.dialog-footer {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 560px;
  text-align: right;
}
</style>