<template>
  <!-- 新增日志信息 -->
  <div>
    <el-dialog
      :title="title"
      :visible.sync="addLogInfoVisible"
      width="650px"
      :before-close="handleClose"
      :close-on-click-modal="false"
      center
    >
      <div class="from-body">
        <el-form
          :model="ruleForm"
          ref="ruleForm"
          :rules="rules"
          label-width="auto"
          class="demo-ruleForm"
        >
          <!-- 操作内容 -->
          <el-form-item label="日志名称" prop="apiName">
            <el-input v-model="ruleForm.apiName"></el-input>
          </el-form-item>
          <!-- URL -->
          <el-form-item label="URL" prop="requestUrl">
            <el-input v-model="ruleForm.requestUrl"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button :style="{ color: buttonTextColor }" @click="handleClose"
          >取 消</el-button
        >
        <el-button
          :style="{ color: buttonTextColor }"
          type="primary"
          @click="submit"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>


<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      title: "新增日志",
      ruleForm: {
        id: "",
        apiName: "",
        requestUrl: "",
      },
      rules: {
        apiName: [
          {
            required: true,
            message: "请输入日志名称",
            trigger: "blur",
          },
        ],
        requestUrl: [
          {
            required: true,
            message: "请输入请求地址",
            trigger: "blur",
          },
        ],
      },
    };
  },
  //监听判断打开新增或编辑弹窗
  watch: {
    addLogInfoVisible(newValue) {
      if (newValue) {
        this.ruleForm.apiName = "";
        this.ruleForm.requestUrl = "";
        this.ruleForm.id = "";
        if (this.isAdd == 1) {
          this.title = "新增日志"; //新增日志信息
        } else if (this.isAdd == 2) {
          this.title = "编辑日志"; //编辑
          this.ruleForm.id = this.currentRole.id;
          this.ruleForm.apiName = this.currentRole.apiName;
          this.ruleForm.requestUrl = this.currentRole.requestUrl;
        }
      }
    },
  },
  computed: {
    ...mapState(["buttonTextColor"]),
  },
  props: ["addLogInfoVisible", "currentRole", "isAdd"],
  methods: {
    handleClose() {
      this.ruleForm = {
        id: "",
        apiName: "",
        requestUrl: "",
      };
      this.$refs["ruleForm"].resetFields();
      this.$emit("addLogInfoClose");
    },
    submit() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          if (this.isAdd == 1) {
            this.request.apiAdd(this.ruleForm).then(() => {
              this.$message.success("新增成功"); //新增成功
              this.$emit("apiList");
              this.handleClose();
            });
          } else {
            this.request.apiEdit(this.ruleForm).then(() => {
              this.$message.success("修改成功"); //修改成功
              this.$emit("apiList");
              this.handleClose();
            });
          }
        }
      });
    },
  },
};
</script>


<style scoped>
.from-body {
  padding: 0 100px;
  box-sizing: border-box;
}
</style>