<template>
  <div class="dilu-page">
    
    <!-- 按钮区域 -->
    <div class="operation-div">
      <el-button :style="{ color: buttonTextColor }" icon="el-icon-plus" type="primary" @click="add">新增协议</el-button>
    </div>

    <!-- 表格 -->
    <DiluTable :list="tableData" :tableConfig="tableConfig" :total="total" :pageSize="searchParams.pageSize" @currentChange="currentChange">
      <el-table-column prop="id" label="协议ID" align="left" width="100"/>
      <el-table-column prop="name" label="协议名称" align="left" width="180"/>
      <el-table-column prop="codec" label="解码协议" align="left" width="400"/>
      <el-table-column prop="createdAt" label="创建时间" align="center" width="150" :formatter="formatDate"/>
      <el-table-column label="操作" align="center" fixed="right" width="200">
        <template slot-scope="scope">
          <div class="table-operation">
            <el-button @click="edit(scope.row)" type="text">编辑协议</el-button>
            <el-button @click="prodict(scope.row)" type="text">协议字典</el-button>
          </div>
        </template>
      </el-table-column>
    </DiluTable>

    <AddProtocol :viewVisible="viewVisible" @viewClose="viewClose" :currentData="currentData" @protocolList="protocolList" :isAdd="isAdd"/>

  </div>
</template>


<script>
import DiluTable from "@/components/public/dilu-table.vue";
import AddProtocol from "./module/addProtocol";
import { mapState } from "vuex";
export default {
  data() {
    return {
      searchParams: {
         name: "",           // 字典名称
         pageNum: 1,         // 页码
         pageSize: 20,       // 每页条数
      },
      tableConfig: {
        isTree: false,
        isPage: true,
      },
      isSearch: false,
      tableData: [],
      total: 0,
      viewVisible: false,
      currentData: {},
      isAdd: true
    };
  },
  components: {
    DiluTable,
    AddProtocol
  },
  computed: {
    ...mapState(["buttonTextColor"]),
  },
  mounted() {
    this.protocolList();
  },
  methods: {
    formatDate(row) {
       if (row.createdAt!=undefined&&row.createdAt.length>=10) {
         return row.createdAt.substring(0,10);
       } else {
         return "";
       }
    },
    //翻页
    currentChange(e) {
       if (!this.isSearch) {
         this.searchParams.pageNum = 1;
         this.searchParams.pageSize = 20;
       }
       this.searchParams.pageNum = e;
       this.protocolList();
    },
    //搜索
    search() {
       this.isSearch = true; //是否在查询
       this.searchParams.pageNum = 1;
       this.total = 0;
       this.protocolList();
     },
     //重置
     reset() {
       this.isSearch = false; //是否在查询
       this.searchParams.pageNum = 1;
       this.searchParams.pageSize = 20;
       this.total = 0;
       this.protocolList();
    },
    //新增
    add() {
     this.isAdd = true;
     this.viewVisible = true;
     this.currentData = {};
    },
    //编辑
    edit(e) {
     this.isAdd = false;
     this.currentData = e;
     this.viewVisible = true;
    },
    prodict(e) {
      this.$router.push({
        path: "/product/protocol/prodict",
        query: {
          protocol: e.id,
        },
      });
    },
    //关闭新增编辑菜单弹窗
    viewClose() {
     this.viewVisible = false;
    },
    //查询列表
    protocolList() {
     this.request.protocolList().then((res) => {
        if (res.code==0) {
          this.tableData = res.data;
          this.total = res.data.length;
        }
     });
    },
  },
};
</script>

<style scoped>
</style>