<template>
  <div class="dilu-page">
    
    <!-- 按钮区域 -->
    <div class="operation-div">
      <el-form ref="form" :model="searchParams" label-width="auto" class="search-form">
         <el-form-item label="所属区域" style="width:250px;">
           <el-select v-model="searchParams.region" style="margin-right:20px;">
              <el-option label="中国区" value="cn" />
              <el-option label="欧洲区" value="eur" />
           </el-select>
         </el-form-item>
         <el-form-item label="网关编码">
            <el-input v-model="searchParams.gateway" clearable/>
         </el-form-item>
       </el-form>
       <el-button style="height:40px;" icon="el-icon-search" type="primary" @click="search">搜索</el-button>
    </div>

    <!-- 表格 -->
    <DiluTable :list="tableData" :tableConfig="tableConfig" :total="total" :pageSize="searchParams.pageSize" @currentChange="currentChange">
      <el-table-column prop="manufacturerName" label="制造商" align="left"/>
      <el-table-column prop="categoryName" label="产品类型" align="left"/>
      <el-table-column prop="modelName" label="产品型号" align="left"/>
      <el-table-column prop="tenantName" label="关联租户" align="left" width="150"/>
      <el-table-column prop="projectCode" label="关联项目" align="left" width="220"/>
      <el-table-column prop="place" label="安装位置" align="left"  width="180"/>
      <el-table-column prop="gateway" label="网关编码" align="left"/>
      <el-table-column prop="address" label="端口地址" align="left"/>
      <el-table-column prop="codec" label="解码协议" align="left"/>
    </DiluTable>

  </div>
</template>


<script>
import DiluTable from "@/components/public/dilu-table.vue";
import { mapState } from "vuex";
export default {
  data() {
    return {
      searchParams: {
         gateway: null,
         region: "cn",       // 区域（cn：中国区，eur：欧洲区）
         pageNum: 1,         // 页码
         pageSize: 20,       // 每页条数
      },
      tableConfig: {
        isTree: false,
        isPage: true,
      },
      isSearch: false,
      tableData: [],
      total: 0,
    };
  },
  components: {
    DiluTable
  },
  computed: {
    ...mapState(["buttonTextColor"]),
  },
  mounted() {
    this.search();
  },
  methods: {
    //翻页
    currentChange(e) {
       if (!this.isSearch) {
         this.searchParams.pageNum = 1;
         this.searchParams.pageSize = 20;
       }
       this.searchParams.pageNum = e;
       this.search();
    },
    //搜索
    search() {
       this.isSearch = true; //是否在查询
       this.searchParams.pageNum = 1;
       this.total = 0;
       this.search();
    },
    //查询列表
    search() {
      this.request.searchCodec(this.searchParams).then((res) => {
          if (res.code==0) {
            this.tableData = res.data;
            this.total = res.data.length;
          }
      });
    },
  },
};
</script>

<style scoped>
</style>