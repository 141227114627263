<template>
<!-- 人员信息 -->
  <div style="height:100%;display:flex">
    <!-- 表格 -->
    <DiluTable
      :list="tableData"
      :tableConfig="tableConfig"
      :total="total"
      :pageSize="pageSize"
      @currentChange="currentChange"
    >
      <el-table-column prop="date" label="#" width="60" align="center">
        <template slot-scope="scope">
          <span>{{ scope.$index + 1 }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="username" label="账号" width="180" align="center"/>
      <el-table-column prop="realName" label="真实姓名" width="180" align="center"/>
      <el-table-column prop="departmentName" label="部门" width="180" align="center"/>
      <el-table-column prop="roleName" label="角色" width="180" align="center"/>
    </DiluTable>
  </div>
</template>

<script>
import DiluTable from "@/components/public/dilu-table.vue";
export default {
  data() {
    return {
      tableConfig: {
        isTree: false,
        isTreeText: "id",
        isPage: true,
      },
    };
  },
  props: ["tableData", "total",'pageSize'],
  components: {
    DiluTable,
  },
  methods:{
    //翻页
    currentChange(e){
      this.$emit('currentChange',e)
    }
  }
};
</script>