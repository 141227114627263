/**
 * 邮箱
 * @param {*} s
 */
export function isEmail(s) {
  return /^([a-zA-Z0-9._-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,3}){1,2})$/.test(s)
}

/**
 * 手机号码
 * @param {*} s
 */
export function isMobile(s) {
  return /^1[0-9]{10}$/.test(s)
}

/**
 * 电话号码
 * @param {*} s
 */
export function isPhone(s) {
  return /^([0-9]{3,4}-)?[0-9]{7,8}$/.test(s)
}

/**
 * URL地址
 * @param {*} s
 */
export function isURL(s) {
  return /^http[s]?:\/\/.*/.test(s)
}


/**
 * 随机生成字符串
 * @param length 字符串的长度
 * @param chats 可选字符串区间（只会生成传入的字符串中的字符）
 * @return string 生成的字符串
 */
export function randomString(length, chats) {
  if (!length) length = 1
  if (!chats) chats = '0123456789qwertyuioplkjhgfdsazxcvbnm'
  let str = ''
  for (let i = 0; i < length; i++) {
    let num = randomNumber(0, chats.length - 1)
    str += chats[num]
  }
  return str
}


/**
 * 随机生成数字
 *
 * 示例：生成长度为 12 的随机数：randomNumber(12)
 * 示例：生成 3~23 之间的随机数：randomNumber(3, 23)
 *
 * @param1 最小值 | 长度
 * @param2 最大值
 * @return int 生成后的数字
 */
export function randomNumber() {
  // 生成 最小值 到 最大值 区间的随机数
  const random = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min)
  }
  if (arguments.length === 1) {
    let [length] = arguments
    // 生成指定长度的随机数字，首位一定不是 0
    let nums = [...Array(length).keys()].map((i) => (i > 0 ? random(0, 9) : random(1, 9)))
    return parseInt(nums.join(''))
  } else if (arguments.length >= 2) {
    let [min, max] = arguments
    return random(min, max)
  } else {
    return Number.NaN
  }
}


// 文件大小转换
export function sizeReset(limit) {
  var size = "";
  if (limit < 0.1 * 1024) {                            //小于0.1KB，则转化成B
    size = limit.toFixed(2) + "B"
  } else if (limit < 0.1 * 1024 * 1024) {            //小于0.1MB，则转化成KB
    size = (limit / 1024).toFixed(2) + "KB"
  } else if (limit < 0.1 * 1024 * 1024 * 1024) {        //小于0.1GB，则转化成MB
    size = (limit / (1024 * 1024)).toFixed(2) + "MB"
  } else {                                            //其他转化成GB
    size = (limit / (1024 * 1024 * 1024)).toFixed(2) + "GB"
  }

  var sizeStr = size + "";                        //转成字符串
  var index = sizeStr.indexOf(".");                    //获取小数点处的索引
  var dou = sizeStr.substr(index + 1, 2)            //获取小数点后两位的值
  if (dou == "00") {                                //判断后两位是否为00，如果是则删除00
    return sizeStr.substring(0, index) + sizeStr.substr(index + 3, 2)
  }
  // console.log(size);
  return size;
}


function formatTen(num) {
  return num > 9 ? num + "" : "0" + num;
}


//转换时间格式
export function resetDate(date) {
  if (date instanceof Date) {
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    var hour = date.getHours();
    var minute = date.getMinutes();
    var second = date.getSeconds();
    return (
      year +
      "-" +
      formatTen(month) +
      "-" +
      formatTen(day) +
      " " +
      formatTen(hour) +
      ":" +
      formatTen(minute) +
      ":" +
      formatTen(second)
    );
  } else {
    return date
  }
}

//转换时间格式
export function resetTime(date) {
  if (date instanceof Date) {
    var hour = date.getHours();
    var minute = date.getMinutes();
    var second = date.getSeconds();
    return (
      formatTen(hour) +
      ":" +
      formatTen(minute) +
      ":" +
      formatTen(second)
    );
  } else {
    return date
  }
}



// 获取当前时间前后aa天时间
export function fun_date(aa) {
  var date1 = new Date();
  var date2 = new Date(date1);
  var hour = date2.getHours();
  var minute = date2.getMinutes();
  var second = date2.getSeconds();
  // 获取当前时间前后aa天时间
  date2.setDate(date1.getDate() + aa);
  var time2 =
    date2.getFullYear() +
    "-" +
    formatTen(date2.getMonth() + 1) +
    "-" +
    formatTen(date2.getDate()) +
    " " +
    formatTen(hour) +
    ":" +
    formatTen(minute) +
    ":" +
    formatTen(second);
  return time2;
}




/**
* 导出 json 数据为 Excle 表格
* @param {json} data 要导出的 json 数据
* @param {String} head 表头, 可选 参数示例：'名字,邮箱,电话'
* @param {*} name 导出的文件名, 可选
*/
export function jsonToExcel(data, head, name) {
  let str = head ? head + "\n" : "";
  data.forEach((item) => {
    // 拼接json数据, 增加 \t 为了不让表格显示科学计数法或者其他格式
    for (let key in item) {
      str = `${str + item[key] + "\t"},`;
    }
    str += "\n";
  });
  console.log(str);
  // encodeURIComponent解决中文乱码
  const uri =
    "data:text/csv;charset=utf-8,\ufeff" + encodeURIComponent(str);
  // 通过创建a标签实现
  const link = document.createElement("a");
  link.href = uri;
  // 对下载的文件命名
  link.download = `${name + ".xls"}`;
  link.click();
}
