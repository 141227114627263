<template>
  <!-- 新增账号 -->
  <el-dialog
    :title="title"
    :visible.sync="accountVisible"
    width="650px"
    :before-close="handleClose"
    :close-on-click-modal="false"
    center
  >
    <div class="from-body">
      <el-form
        :model="ruleForm"
        ref="ruleForm"
        :rules="rules"
        label-width="auto"
        class="demo-ruleForm"
      >
        <el-form-item label="账号" prop="username">
          <el-input
            v-model="ruleForm.username"
            :disabled="accountManagementAddState == 2"
          ></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input
            v-model="ruleForm.password"
            show-password
            autocomplete="new-password"
            :disabled="accountManagementAddState == 2"
          >
            <template slot="append">
              <el-button
                :style="{ color: buttonTextColor }"
                @click="randomString"
                >自动生成</el-button
              >
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="部门" prop="departmentId">
          <el-select
            v-model="ruleForm.departmentId"
            placeholder="请选择部门"
            :disabled="accountManagementAddState == 2"
          >
            <el-option
              v-for="item in departments"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="角色" prop="roleIds">
          <el-checkbox-group
            v-model="ruleForm.roleIds"
            :disabled="accountManagementAddState == 2"
          >
            <el-checkbox
              :label="item.value"
              v-for="(item, index) in roleList"
              :key="index"
              >{{ item.label }}</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="真实姓名" prop="realName">
          <el-input
            v-model="ruleForm.realName"
            :disabled="accountManagementAddState == 2"
          ></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input
            v-model="ruleForm.email"
            :disabled="accountManagementAddState == 2"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button :style="{ color: buttonTextColor }" @click="handleClose">{{
        accountManagementAddState != 2 ? "取 消" : "关 闭"
      }}</el-button>
      <el-button
        :style="{ color: buttonTextColor }"
        type="primary"
        @click="submit"
        v-if="accountManagementAddState != 2"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>


<script>
import { validateUsername } from "@/utils/util/test";
import { randomString } from "@/utils/util/validate";
import { mapState } from "vuex";
export default {
  data() {
    return {
      ruleForm: {
        departmentId: "", //部门id
        email: "", //邮箱
        imageBean: null, //	图片信息
        password: "", //密码
        realName: "", //真实姓名
        roleIds: [], //角色ids
        username: "", //账号名称
      },
      title: "新增账号",
      roleList: [], //角色列表
      departments: [], //部门列表
      rules: {
        username: [
          {
            validator: validateUsername,
            trigger: "blur",
          },
          {
            required: true,
            message: "请填写账号",
            trigger: "blur",
          },
          {
            min: 2,
            max: 16,
            message: "请填写2-16位账号",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "请填写密码",
            trigger: "blur",
          },
          {
            min: 6,
            max: 18,
            message: "请填写6-18位密码",
            trigger: "blur",
          },
        ],
        departmentId: [
          { required: true, message: "请选择部门", trigger: "change" },
        ],
        roleIds: [
          {
            type: "array",
            required: true,
            message: "请至少选择一个角色",
            trigger: "change",
          },
        ],
        realName: [
          {
            required: true,
            message: "请填写真实姓名",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "请填写邮箱",
            trigger: "blur",
          },
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  computed: {
    ...mapState(["buttonTextColor"]),
  },
  mounted() {
    this.departmentManagementListAll();
    this.roleManagementListAll();
  },
  watch: {
    accountVisible(newValue) {
      if (newValue) {
        if (this.accountManagementAddState == 0) {
          this.randomString();
          this.title = "新增账号";
        }
        if (this.accountManagementAddState == 1) {
          this.title = "编辑账号";
        }
        if (this.accountManagementAddState == 2) {
          this.title = "查看账号";
        }
        if (this.accountManagementAddState != 0) {
          this.ruleForm.departmentId = this.currentAccount.departmentId;
          this.ruleForm.email = this.currentAccount.email;
          this.ruleForm.imageBean = null;
          this.ruleForm.password = this.currentAccount.post;
          this.ruleForm.realName = this.currentAccount.realName;
          this.ruleForm.id = this.currentAccount.id;
          for (let i in this.currentAccount.roles) {
            this.ruleForm.roleIds.push(this.currentAccount.roles[i].id);
          }
          this.ruleForm.username = this.currentAccount.username;
        }
      }
    },
  },
  props: ["accountVisible", "accountManagementAddState", "currentAccount"],
  methods: {
    handleClose() {
      this.ruleForm = {
        departmentId: "", //部门id
        email: "", //邮箱
        imageBean: null, //	图片信息
        password: "", //密码
        realName: "", //真实姓名
        roleIds: [], //角色ids
        username: "", //账号名称
      };
      this.$refs["ruleForm"].resetFields();
      this.$emit("accountClose");
    },
    //自动生成密码
    randomString() {
      this.ruleForm.password = randomString(6);
    },
    //部门管理-查询所有部门
    departmentManagementListAll() {
      this.request.departmentManagementListAll().then((res) => {
        let arr = [];
        for (let i in res.data.departmentList) {
          arr.push({
            value: res.data.departmentList[i].id,
            label: res.data.departmentList[i].departmentName,
          });
        }
        this.departments = arr;
      });
    },
    //查询所有角色
    roleManagementListAll() {
      this.request.roleManagementListAll().then((res) => {
        let arr = [];
        for (let i in res.data) {
          arr.push({
            value: res.data[i].id,
            label: res.data[i].roleName,
          });
        }
        this.roleList = arr;
      });
    },
    //提交
    submit() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          if (this.accountManagementAddState == 0) {
            this.request.accountManagementAdd(this.ruleForm).then(() => {
              this.$message.success("新增成功");
              this.handleClose();
              this.$emit("accountManagementList");
            });
          } else {
            this.request.accountManagementEdit(this.ruleForm).then(() => {
              this.$message.success("编辑成功");
              this.handleClose();
              this.$emit("accountManagementList");
            });
          }
        }
      });
    },
  },
};
</script>


<style scoped>
.from-body {
  padding: 0 60px;
  box-sizing: border-box;
}
</style>