<template>
  <el-dialog :title="isAdd ? '新增模块记录' : '编辑模块记录'" :visible.sync="viewVisible" width="650px" :before-close="handleClose" :close-on-click-modal="false" center>
    <div class="from-body">
      <el-form :model="ruleForm" ref="ruleForm" :rules="rules" label-width="auto">
        <el-form-item label="生产厂商" prop="manufacturer">
          <el-select v-model="ruleForm.manufacturer" style="width:400px;" filterable clearable>
            <el-option v-for="(item, index) in fList" :key="index" :label="item.name" :value="item.id"/>
          </el-select>
        </el-form-item>
        <el-form-item label="产品类型" prop="category">
          <el-select v-model="ruleForm.category" @change="onChange" style="width:400px;" filterable clearable>
            <el-option v-for="(item, index) in cList" :key="index" :label="item.name" :value="item.id"/>
          </el-select>
        </el-form-item>
        <el-form-item label="产品型号" prop="model">
          <el-select v-model="ruleForm.model" style="width:400px;" filterable clearable>
            <el-option v-for="(item, index) in mList" :key="index" :label="item.name" :value="item.id"/>
          </el-select>
        </el-form-item>
        <el-form-item label="关联租户" prop="tenant">
          <el-select v-model="ruleForm.tenant" style="width:400px;" filterable clearable>
            <el-option v-for="(item, index) in tList" :key="index" :label="item.name" :value="item.id"/>
          </el-select>
        </el-form-item>
        <el-form-item label="开始范围" prop="startImei">
          <el-input v-model="ruleForm.startImei" clearable/>
        </el-form-item>
        <el-form-item label="截止范围" prop="endImei">
          <el-input v-model="ruleForm.endImei" clearable/>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button :style="{ color: buttonTextColor }" @click="handleClose">取 消</el-button>
      <el-button :style="{ color: buttonTextColor }" type="primary" @click="submit">确 定</el-button>
    </span>
  </el-dialog>
</template>


<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      ruleForm: {
        id: null,
        category: null,
        model: null,
        manufacturer: null,
        tenant: null,
        startImei: null,
        endImei: null,
        region: null
      },
      cList: [],
      mList: [],
      fList: [],
      tList: [],
      rules: {
        category: [
          {
            required: true,
            message: "请指定产品类型",
            trigger: "blur",
          },
        ],
        model: [
          {
            required: true,
            message: "请指定产品型号",
            trigger: "blur",
          },
        ],
        manufacturer: [
          {
            required: true,
            message: "请指定制造商",
            trigger: "blur",
          },
        ]
      },
    };
  },
  watch: {
    viewVisible(newVal) {
      if (newVal) {
        this.ruleForm.region=this.region;
        if (this.isAdd) {
          //新增
          this.ruleForm.id = null;
          this.ruleForm.category = null;
          this.ruleForm.model = null;
          this.ruleForm.manufacturer = null;
          this.ruleForm.tenant = null;
          this.ruleForm.startImei = null;
          this.ruleForm.endImei = null;
        } else {
          //修改
          this.ruleForm.id = this.currentData.id;
          this.ruleForm.category = this.currentData.category;
          this.ruleForm.model = this.currentData.model;
          this.ruleForm.manufacturer = this.currentData.manufacturer;
          this.ruleForm.tenant = this.currentData.tenant;
          this.ruleForm.startImei = this.currentData.startImei;
          this.ruleForm.endImei = this.currentData.endImei;
        }
        this.categoryList();
        if (this.ruleForm.category!=null) {
          this.modelList();
        }
        this.manufacturerList();
        this.tenantList();
      }
    },
  },
  computed: {
    ...mapState(["buttonTextColor"]),
  },
  props: ["viewVisible", "currentData", "isAdd", "region"],
  methods: {
    onChange() {
      this.ruleForm.model=null;
      this.modelList();
    },
    //查询类型
    categoryList() {
       this.request.categoryList().then((res) => {
          if (res.code==0) {
            this.cList = res.data;
          }
       });
    },
    //查询型号
    modelList() {
      var params = {
        category: this.ruleForm.category
      }
      this.request.modelList(params).then((res) => {
          if (res.code==0) {
            this.mList = res.data;
          }
      });
    },
    //查询制造商
    manufacturerList() {
       this.request.manufacturerList().then((res) => {
          if (res.code==0) {
            this.fList = res.data;
          }
       });
    },
    //查询租户
    tenantList() {
      var params={
        "region":this.region
      };
      this.request.tenantAll(params).then((res) => {
        if (res.code==0) {
          this.tList = res.data;
        }
      });
    },
    //处理关闭
    handleClose() {
      this.ruleForm = {
        id: null,
        category: null,
        model: null,
        manufacturer: null,
        tenant: null,
        startImei: null,
        endImei: null,
        imei: null,
        address: null,
        region: null
      };
      this.$refs["ruleForm"].resetFields();
      this.$emit("viewClose");
    },
    //提交保存
    submit() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          if (this.isAdd) {
            this.request.moduleAdd(this.ruleForm).then((res) => {
              if (res.code==0) {
                this.$message.success("新增成功");
                this.$emit("moduleList");
                this.handleClose();
              }
            });
          } else {
            this.request.moduleEdit(this.ruleForm).then((res) => {
              if (res.code==0) {
                this.$message.success("编辑成功");
                this.$emit("moduleList");
                this.handleClose();
              }
            });
          }
        }
      });
    },
  },
};
</script>

<style scoped>
.from-body {
  padding: 0 60px;
  box-sizing: border-box;
}
</style>