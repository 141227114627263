<template>
  <el-dialog :title="isAdd ? '新增菜单' : '编辑菜单'" :visible.sync="viewVisible" width="650px" :before-close="handleClose" :close-on-click-modal="false" center>
    <div class="from-body">
      <el-form :model="ruleForm" ref="ruleForm" :rules="rules" label-width="auto" class="demo-ruleForm">
        <el-form-item label="菜单名称" prop="menuName">
          <el-input v-model="ruleForm.menuName"/>
        </el-form-item>
        <el-form-item label="显示顺序" prop="sort">
          <el-input v-model="ruleForm.sort"/>
        </el-form-item>
        <el-form-item label="权限路由" prop="url">
          <el-input v-model="ruleForm.url"/>
        </el-form-item>
        <el-form-item label="菜单图标" prop="menuIcon">
          <el-input v-model="ruleForm.menuIcon" readonly>
            <el-button :style="{ color: buttonTextColor }" slot="append" @click="innerVisible = true">选择</el-button>
          </el-input>
        </el-form-item>
        <el-form-item label="是否隐藏">
          <el-switch v-model="ruleForm.hidden"/>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button :style="{ color: buttonTextColor }" @click="handleClose">取 消</el-button>
      <el-button :style="{ color: buttonTextColor }" type="primary" @click="submit">确 定</el-button>
    </span>
    <el-dialog width="800px" title="icons" :visible.sync="innerVisible" append-to-body>
      <div class="icon-list">
        <div class="icon-item" v-for="item of elementIcons" :key="item" @click="selectIcon('el-icon-' + item)">
          <i :class="'el-icon-' + item" />
        </div>
      </div>
    </el-dialog>
  </el-dialog>
</template>


<script>
import { mapState } from "vuex";
import elementIcons from "@/utils/util/element-icons";
export default {
  data() {
    return {
      elementIcons: [], // 图标库
      innerVisible: false,
      ruleForm: {
        isLeaf: 1,     //是否是子节点
        level: 1,      //所在层级
        menuName: "",  //菜单名称
        menuPid: "",   //菜单的父节点
        status: 0,     //状态，是否启用
        sort: 0,       //菜单显示顺序
        url: "",       //权限路由
        hidden: false, //是否隐藏 true/false
        menuIcon: "",  //菜单图标
      },
      rules: {
        menuName: [
          {
            required: true,
            message: "请填写菜单名称",
            trigger: "blur",
          },
        ],
        menuIcon: {
          required: true,
          message: "请选择icon",
          trigger: "blur",
        },
        url: [
          {
            required: true,
            message: "请填写权限路由",
            trigger: "blur",
          },
        ],
        sort: [
          {
            required: true,
            message: "请填写菜单显示顺序",
            trigger: "blur",
          },
        ],
        menuPid: [
          {
            required: true,
            message: "父菜单ID不能为空",
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {
    viewVisible(newVal) {
      if (newVal) {
        this.elementIcons = elementIcons;
        if (this.currentMenu.level==null) {
          //新增一级菜单
            this.ruleForm.level = 1;
            this.ruleForm.menuPid = 0;
        } else {
          //新增
          if (this.isAdd) {
            this.ruleForm.menuPid = this.currentMenu.id;
            this.ruleForm.sort = this.currentMenu.sort;
            this.ruleForm.level = this.currentMenu.level + 1;
          } else {
            //修改
            this.ruleForm.menuPid = this.currentMenu.menuPid;
            this.ruleForm.level = this.currentMenu.level;
            this.ruleForm.menuName = this.currentMenu.menuName;
            this.ruleForm.url = this.currentMenu.url;
            this.ruleForm.id = this.currentMenu.id;
            this.ruleForm.hidden = this.currentMenu.hidden;
            this.ruleForm.menuIcon = this.currentMenu.menuIcon;
            this.ruleForm.sort = this.currentMenu.sort;
          }
        }
      }
    },
  },
  computed: {
    ...mapState(["buttonTextColor"]),
  },
  props: ["viewVisible", "currentMenu", "isAdd"],
  methods: {
    handleClose() {
      this.ruleForm = {
        isLeaf: 1, //是否是子节点
        level: 1, //所在层级
        menuName: "", //菜单名称
        menuPid: 0, //菜单的父节点
        status: 0, //状态，是否启用
        sort: 0,   //菜单显示顺序
        url: "",   //权限路由
        hidden: false, //是否隐藏 true/false
        menuIcon: "", //菜单图标
      };
      this.$refs["ruleForm"].resetFields();
      this.ruleForm.id = "";
      this.$emit("viewClose");
    },
    //icon选择
    selectIcon(e) {
      this.ruleForm.menuIcon = e;
      this.innerVisible = false;
    },
    //提交
    submit() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          if (this.isAdd) {
            this.request.tenantMenuAdd(this.ruleForm).then((res) => {
              if (res.code==0) {
                this.$message.success("新增成功");
                this.$emit("listTenantMenuButtons");
                this.handleClose();
              }
            });
          } else {
            this.request.tenantMenuEdit(this.ruleForm).then((res) => {
              if (res.code==0) {
                this.$message.success("编辑成功");
                this.$emit("listTenantMenuButtons");
                this.handleClose();
              }
            });
          }
        }
      });
    },
  },
};
</script>


<style scoped>
.from-body {
  padding: 0 60px;
  box-sizing: border-box;
}
.icon-list {
  display: flex;
  flex-wrap: wrap;
}
.icon-item {
  width: 30px;
  height: 30px;
  cursor: pointer;
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon-item i {
  font-size: 20px;
}
</style>