<template>
  <div class="dilu-page">
    
    <!-- 按钮区域 -->
    <div class="operation-div">
      <el-button :style="{ color: buttonTextColor }" icon="el-icon-plus" type="primary" @click="add">新增产品</el-button>
    </div>

    <!-- 表格 -->
    <DiluTable :list="tableData" :tableConfig="tableConfig" :total="total" :pageSize="searchParams.pageSize" @currentChange="currentChange">
      <el-table-column prop="id" label="产品ID" width="100" align="left"/>
      <el-table-column prop="categoryName" label="产品类型" width="120" align="left"/>
      <el-table-column prop="modelName" label="产品型号" width="120" align="left"/>
      <el-table-column prop="manufacturerName" label="制造商" width="120" align="left"/>
      <el-table-column prop="name" label="产品名称" width="150" align="left"/>
      <el-table-column prop="status" label="启用状态" width="100" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.status==10">启用中</span>
          <span v-if="scope.row.status==20">已下架</span>
        </template>
      </el-table-column>
      <el-table-column prop="protocolName" label="协议名称" width="120" align="left"/>
      <el-table-column prop="codec" label="解码协议" width="350" align="left"/>
      <el-table-column prop="createdAt" label="创建时间" align="center" width="150" :formatter="formatDate"/>
      <el-table-column label="操作" align="center" fixed="right"  width="160">
        <template slot-scope="scope">
          <div class="table-operation">
            <el-button @click="edit(scope.row)" type="text">编辑</el-button>
            <el-button v-if="scope.row.status==10" @click="status(scope.row)" type="text">禁用</el-button>
            <el-button v-if="scope.row.status==20" @click="status(scope.row)" type="text">启用</el-button>
          </div>
        </template>
      </el-table-column>
    </DiluTable>

    <AddProduct :viewVisible="viewVisible" @viewClose="viewClose" :currentData="currentData" @productList="productList" :isAdd="isAdd"/>

  </div>
</template>


<script>
import DiluTable from "@/components/public/dilu-table.vue";
import AddProduct from "./module/addProduct";
import { mapState } from "vuex";
export default {
  data() {
    return {
      searchParams: {
         name: "",  
         category: null,
         model: null,
         manufacturer: null,
         pageNum: 1,         
         pageSize: 20,       
      },
      tableConfig: {
        isTree: false,
        isPage: true,
      },
      isSearch: false,
      tableData: [],
      total: 0,
      viewVisible: false,
      currentData: {},
      isAdd: true
    };
  },
  components: {
    DiluTable,
    AddProduct
  },
  computed: {
    ...mapState(["buttonTextColor"]),
  },
  mounted() {
    this.productList();
  },
  methods: {
    formatDate(row) {
      if (row.createdAt!=undefined&&row.createdAt.length>=10) {
        return row.createdAt.substring(0,10);
      } else {
        return "";
      }
    },
    //翻页
    currentChange(e) {
       if (!this.isSearch) {
         this.searchParams.pageNum = 1;
         this.searchParams.pageSize = 20;
       }
       this.searchParams.pageNum = e;
       this.productList();
     },
     //搜索
     search() {
       this.isSearch = true; //是否在查询
       this.searchParams.pageNum = 1;
       this.total = 0;
       this.productList();
     },
     //重置
     reset() {
       this.isSearch = false; //是否在查询
       this.searchParams.pageNum = 1;
       this.searchParams.pageSize = 20;
       this.total = 0;
       this.productList();
     },
    //新增
    add() {
     this.isAdd = true;
     this.viewVisible = true;
     this.currentData = {};
    },
    //编辑
    edit(e) {
     this.isAdd = false;
     this.currentData = e;
     this.viewVisible = true;
    },
    //启用禁用
    status(item) {
      if (item.status==10) {
        item.status=20;
      } else {
        item.status=10;
      }
      this.request.productEdit(item).then((res) => {
        if (res.code==0) {
          this.$message.success("操作成功");
          this.productList();
        }
      });
    },
    //关闭新增编辑菜单弹窗
    viewClose() {
     this.viewVisible = false;
    },
    //查询列表
    productList() {
      this.request.productList(this.searchParams).then((res) => {
         if (res.code==0) {
           this.tableData = res.data;
           this.total = res.data.length;
         }
      });
    },
  },
};
</script>

<style scoped>
</style>