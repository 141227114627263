<template>
  <el-dialog
    :title="isButtonAdd ? '新增按钮' : '编辑按钮'"
    :visible.sync="buttonVisible"
    width="650px"
    :before-close="handleClose"
    :close-on-click-modal="false"
    center
  >
    <div class="from-body">
      <el-form
        :model="ruleForm"
        ref="ruleForm"
        :rules="rules"
        label-width="auto"
        class="demo-ruleForm"
      >
        <el-form-item label="按钮名称" prop="buttonName">
          <el-input v-model="ruleForm.buttonName"></el-input>
        </el-form-item>
        <el-form-item label="按钮标识" prop="perms">
          <el-input v-model="ruleForm.perms"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button :style="{ color: buttonTextColor }" @click="handleClose"
        >取 消</el-button
      >
      <el-button
        :style="{ color: buttonTextColor }"
        type="primary"
        @click="submit"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>


<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      ruleForm: {
        buttonName: "", //按钮名称
        perms: "", //按钮权限编码
        pid: "", //按钮的父节点
        status: 0, //状态，是否启用
      },
      rules: {
        buttonName: [
          {
            required: true,
            message: "请填写按钮名称",
            trigger: "blur",
          },
        ],
        perms: [
          {
            required: true,
            message: "请填写按钮标识",
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {
    buttonVisible(newVal) {
      if (newVal) {
        if (this.isButtonAdd) {
          this.ruleForm.pid = this.currentMenu.id;
        } else {
          this.ruleForm.pid = this.currentMenu.menuPid;
          this.ruleForm.id = Math.abs(this.currentMenu.id);
          this.ruleForm.buttonName = this.currentMenu.menuName;
          this.ruleForm.perms = this.currentMenu.url;
        }
      }
    },
  },
  computed: {
    ...mapState(["buttonTextColor"]),
  },
  props: ["buttonVisible", "currentMenu", "isButtonAdd"],
  methods: {
    handleClose() {
      this.ruleForm = {
        buttonName: "", //按钮名称
        perms: "", //按钮权限编码
        pid: "", //按钮的父节点
        status: 0, //状态，是否启用
        id: "",
      };
      this.$refs["ruleForm"].resetFields();
      this.$emit("buttonClose");
    },
    //确定
    submit() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          if (this.isButtonAdd) {
            this.request.menuManagementAddButton(this.ruleForm).then((res) => {
              if (res.code==0) {
                this.$message.success("新增按钮成功");
                this.handleClose();
                this.$emit("listWithButtons");
              }
            });
          } else {
            this.request.menuManagementEditButton(this.ruleForm).then((res) => {
              if (res.code==0) {
                this.$message.success("编辑按钮成功");
                this.handleClose();
                this.$emit("listWithButtons");
              }
            });
          }
        }
      });
    },
  },
};
</script>


<style scoped>
.from-body {
  padding: 0 60px;
  box-sizing: border-box;
}
</style>