<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="roleVisible"
      width="650px"
      :before-close="handleClose"
      :close-on-click-modal="false"
      center
    >
      <div class="from-body">
        <el-form
          :model="ruleForm"
          ref="ruleForm"
          :rules="rules"
          label-width="auto"
          class="demo-ruleForm"
        >
          <el-form-item label="角色名称" prop="roleName">
            <el-input
              v-model="ruleForm.roleName"
              :disabled="isAdd == 3 ? true : false"
            ></el-input>
          </el-form-item>
          <el-form-item label="角色描述" prop="roleDesc">
            <el-input
              type="textarea"
              rows="4"
              v-model="ruleForm.roleDesc"
              :disabled="isAdd == 3 ? true : false"
            ></el-input>
          </el-form-item>
          <el-form-item label="角色权限">
            <el-button
              :style="{ color: buttonTextColor }"
              type="primary"
              @click="jurisdiction = true"
              icon="el-icon-s-tools"
              >选择</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button :style="{ color: buttonTextColor }" @click="handleClose">{{
          isAdd == 3 ? "关 闭" : "取 消"
        }}</el-button>
        <el-button
          :style="{ color: buttonTextColor }"
          type="primary"
          @click="submit"
          v-if="isAdd != 3"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <!-- 权限列表 -->
    <el-drawer
      title="权限列表"
      :before-close="jurisdictionClose"
      :visible.sync="jurisdiction"
      direction="rtl"
      custom-class="demo-drawer"
      ref="drawer"
    >
      <div class="demo-drawer__content">
        <div class="jurisdiction-content">
          <el-tree
            ref="tree"
            :data="menuTree"
            :show-checkbox="isAdd == 3 ? false : true"
            node-key="id"
            :check-strictly="iStrictly"
            default-expand-all
            :props="defaultProps"
            :default-checked-keys="checkedKeys"
          >
          </el-tree>
        </div>
        <div class="demo-drawer__footer">
          <el-button
            :style="{ color: buttonTextColor }"
            @click="jurisdictionClose"
            >关闭</el-button
          >
          <el-button
            :style="{ color: buttonTextColor }"
            type="primary"
            @click="submitSelect"
            v-if="isAdd != 3"
            >确定</el-button
          >
        </div>
      </div>
    </el-drawer>
  </div>
</template>


<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      title: "新增",
      jurisdiction: false,
      ruleForm: {
        buttonIds: [], //按钮ids
        roleDesc: "", //	角色描述
        menuIds: [], //菜单ids
        roleCode: "", //角色code
        roleName: "", //角色名称
        sort: 0, //排序
        status: 0,
      },
      iStrictly: true, //父子是否关联
      checkedKeys: [],
      rules: {
        roleName: [
          {
            required: true,
            message: "请填写角色名称",
            trigger: "blur",
          },
        ],
        roleDesc: [
          {
            required: true,
            message: "请填写角色描述",
            trigger: "blur",
          },
        ],
      },
      menuTree: [],
      defaultProps: {
        children: "children",
        label: "menuName",
      },
    };
  },
  watch: {
    roleVisible(newVal) {
      if (newVal) {
        this.ruleForm.buttonIds = [];
        this.ruleForm.roleDesc = "";
        this.ruleForm.menuIds = [];
        this.ruleForm.roleName = "";
        if (this.isAdd == 1) {
          this.title = "新增";
          this.listAllWithButtons();
          this.iStrictly = false;
          //新增
        } else if (this.isAdd == 2) {
          this.title = "编辑";
          this.listAllWithButtons();
          //编辑
          this.ruleForm.buttonIds = this.currentRole.buttonIds;
          this.ruleForm.roleDesc = this.currentRole.roleDesc;
          this.ruleForm.menuIds = this.currentRole.menuIds;
          this.ruleForm.roleName = this.currentRole.roleName;
          this.ruleForm.id = this.currentRole.id;
          this.checkedKeys = [
            ...this.currentRole.menuIds,
            ...this.currentRole.buttonIds,
          ];
          this.iStrictly = true;
        } else {
          console.log(this.currentRole);
          this.title = "查看";
          this.menuTree = this.currentRole.treeList;
          this.ruleForm.buttonIds = this.currentRole.buttonIds;
          this.ruleForm.roleDesc = this.currentRole.roleDesc;
          this.ruleForm.menuIds = this.currentRole.menuIds;
          this.ruleForm.roleName = this.currentRole.roleName;
          //查看
        }
      }
    },
  },
  props: ["roleVisible", "isAdd", "currentRole"],
  computed: {
    ...mapState(["buttonTextColor"]),
  },
  methods: {
    handleClose() {
      this.menuTree = this.removedForArrChild(this.menuTree);
      this.$refs["ruleForm"].resetFields();
      if (this.$refs.tree) {
        this.$nextTick(() => {
          this.$refs.tree.setCheckedKeys([]);
        });
      }
      this.$emit("roleClose");
    },
    removedForArrChild(arr) {
      for (let i in arr) {
        arr[i].disabled = false;
        if (arr[i].children && arr[i].children.length > 0) {
          this.removedForArrChild(arr[i].children);
        }
      }
      return arr;
    },
    //关闭权限弹窗
    jurisdictionClose() {
      this.jurisdiction = false;
    },
    //确定权限
    submitSelect() {
      let arrayId = this.$refs.tree
        .getCheckedKeys()
        .concat(this.$refs.tree.getHalfCheckedKeys());

      let arr1 = [];
      let arr2 = [];
      for (let i in arrayId) {
        if (arrayId[i] > 0) {
          arr1.push(arrayId[i]);
        } else {
          arr2.push(Math.abs(arrayId[i]));
        }
      }
      console.log(this.ruleForm);
      this.ruleForm.menuIds = arr1;
      this.ruleForm.buttonIds = arr2;
      this.jurisdictionClose();
    },
    //获取菜单列表
    listAllWithButtons() {
      this.request.listAllWithButtons().then((res) => {
        this.menuTree = res.data;
      });
    },
    //确定
    submit() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          if (this.ruleForm.menuIds.length < 1) {
            return this.$message.error("请选择角色权限");
          }
          if (this.isAdd == 1) {
            this.request.roleManagementAdd(this.ruleForm).then((res) => {
              if (res.code==0) {
                this.$message.success("新增成功");
              }
            });
          } else {
            this.request.roleManagementEdit(this.ruleForm).then((res) => {
              if (res.code==0) {
                this.$message.success("编辑成功");
              }
            });
          }
          setTimeout(() => {
            this.$emit("roleManagementList");
            this.handleClose();
          }, 500);
        }
      });
    },
  },
};
</script>


<style scoped>
.from-body {
  padding: 0 60px;
  box-sizing: border-box;
}

.demo-drawer__content {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  padding-top: 0;
}
.jurisdiction-content {
  flex: 1;
  margin-top: 20px;
}
.demo-drawer__footer {
  padding: 20px 0;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}
.top-switch {
  display: flex;
  margin-bottom: 20px;
  justify-content: space-around;
}
</style>