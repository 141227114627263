<template>
  <div class="form">
    <el-drawer :title="title" :before-close="jurisdictionClose" :visible.sync="productVisible" direction="rtl" custom-class="demo-drawer" ref="drawer">
      <div class="demo-drawer__content">
        <div class="jurisdiction-content">
          <el-tree 
            ref="tree" 
            :data="itemList" 
            node-key="id" 
            :check-strictly="false"
            show-checkbox
            default-expand-all
            :props="defaultProps"
            :default-checked-keys="checkedKeys"/>
        </div>
        <div class="demo-drawer__footer">
          <el-button :style="{ color: buttonTextColor }" @click="jurisdictionClose">关闭</el-button>
          <el-button :style="{ color: buttonTextColor }" type="primary" @click="submitSelect">确定</el-button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      itemList: [],
      checkedKeys: [],
      iStrictly: false, 
      jurisdiction: true,
      title: "产品分配",
      idList: [], 
      defaultProps: {
        children: "itemList",
        label: "name",
      },
    };
  },
  components: {

  },
  watch: {
    productVisible(newVal) {
      if (newVal) {
        this.title="产品分配（"+this.shortName+"）";
        this.listTenantProduct();
      }
    },
  },
  computed: {
    ...mapState(["buttonTextColor"]),
  },
  props: ["tenantId","shortName","productVisible"],
  mounted() {},
  methods: {
    listTenantProduct() {
      var params={};
      params['tenantId']=this.tenantId;
      this.request.listTenantProduct(params).then((res) => {
        this.itemList = res.data.itemList;
        this.checkedKeys = res.data.idList;
      });
    },
    //关闭选择弹窗
    jurisdictionClose() {
      this.jurisdiction = false;
      this.$emit("productClose");
    },
    //提交产品选择
    submitSelect() {
      let arrays = this.$refs.tree.getCheckedKeys().concat(this.$refs.tree.getHalfCheckedKeys());
      this.idList = [];
      for (var i = 0; i < arrays.length; i++){
        if (arrays[i].startsWith("C")||arrays[i].startsWith("M")||arrays[i].startsWith("F")) {
          //什么也不用做
        } else {
          this.idList.push(parseInt(arrays[i]));
        }
      }
      var params={};
      params['tenantId']=this.tenantId;
      params['idList']=this.idList;
      this.request.saveTenantProduct(params).then((res) => {
        if (res.code==0) {
          this.$message.success("操作成功");
          this.jurisdictionClose();
        }
      });
    }
  },
};
</script>

<style scoped>
.form {
  padding: 30px;
  box-sizing: border-box;
}
.line-form {
  width: 100%;
  display: flex;
}
.demo-drawer__content {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  padding-top: 0;
}
.jurisdiction-content {
  flex: 1;
  margin-top: 20px;
}
.demo-drawer__footer {
  padding: 20px 0;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}
</style>