<template>
  <div class="dilu-page">
    
    <!-- 按钮区域 -->
    <div class="operation-div">
      <el-select v-model="searchParams.region" style="margin-right:20px;" @change="moduleList" placeholder="请选择区域">
        <el-option label="中国区" value="cn" />
        <el-option label="欧洲区" value="eur" />
      </el-select>
      <el-button :style="{ color: buttonTextColor }" icon="el-icon-plus" type="primary" @click="add">新增记录</el-button>
    </div>

    <!-- 表格 -->
    <DiluTable :list="tableData" :tableConfig="tableConfig" :total="total" :pageSize="searchParams.pageSize" @currentChange="currentChange">
      <el-table-column prop="manufacturerName" label="制造商" align="left"/>
      <el-table-column prop="categoryName" label="产品类型" align="left"/>
      <el-table-column prop="modelName" label="产品型号" align="left"/>
      <el-table-column prop="tenantName" label="关联租户" align="left"/>
      <el-table-column prop="startImei" label="IMEI开始" align="center"/>
      <el-table-column prop="endImei" label="IMEI结束" align="center"/>
      <el-table-column prop="createdAt" label="创建时间" align="center" :formatter="formatDate"/>
      <el-table-column label="操作" align="center" fixed="right">
        <template slot-scope="scope">
          <div class="table-operation">
            <el-button @click="edit(scope.row)" type="text">编辑</el-button>
          </div>
        </template>
      </el-table-column>
    </DiluTable>

    <AddModule :viewVisible="viewVisible" @viewClose="viewClose" :region="searchParams.region" :currentData="currentData" @moduleList="moduleList" :isAdd="isAdd"/>

  </div>
</template>


<script>
import DiluTable from "@/components/public/dilu-table.vue";
import AddModule from "./module/addModule";
import { mapState } from "vuex";
export default {
  data() {
    return {
      searchParams: {
         region: "cn",       // 区域（cn：中国区，eur：欧洲区）
         pageNum: 1,         // 页码
         pageSize: 20,       // 每页条数
      },
      tableConfig: {
        isTree: false,
        isPage: true,
      },
      isSearch: false,
      tableData: [],
      total: 0,
      viewVisible: false,
      currentData: {},
      isAdd: true,
    };
  },
  components: {
    DiluTable,
    AddModule
  },
  computed: {
    ...mapState(["buttonTextColor"]),
  },
  mounted() {
    this.moduleList();
  },
  methods: {
    formatDate(row) {
       if (row.createdAt!=undefined&&row.createdAt.length>=10) {
         return row.createdAt.substring(0,10);
       } else {
         return "";
       }
    },
    //翻页
    currentChange(e) {
       if (!this.isSearch) {
         this.searchParams.pageNum = 1;
         this.searchParams.pageSize = 20;
       }
       this.searchParams.pageNum = e;
       this.moduleList();
     },
     //搜索
     search() {
       this.isSearch = true; //是否在查询
       this.searchParams.pageNum = 1;
       this.total = 0;
       this.moduleList();
     },
     //重置
     reset() {
       this.isSearch = false; //是否在查询
       this.searchParams.pageNum = 1;
       this.searchParams.pageSize = 20;
       this.total = 0;
       this.moduleList();
     },
    //新增
    add() {
     this.isAdd = true;
     this.viewVisible = true;
     this.currentData = {};
    },
    //编辑
    edit(e) {
     this.isAdd = false;
     this.currentData = e;
     this.viewVisible = true;
    },
    //关闭新增编辑菜单弹窗
   viewClose() {
     this.viewVisible = false;
   },
   //查询列表
   moduleList() {
    this.request.moduleList(this.searchParams).then((res) => {
        if (res.code==0) {
          this.tableData = res.data;
          this.total = res.data.length;
        }
    });
   },
  },
};
</script>

<style scoped>
</style>