<template>
  <el-dialog
    title="新增部门"
    :visible.sync="departVisible"
    width="650px"
    :before-close="handleClose"
    :close-on-click-modal="false"
    center
  >
    <div class="from-body">
      <el-form
        :model="ruleForm"
        ref="ruleForm"
        :rules="rules"
        label-width="auto"
        class="demo-ruleForm"
      >
        <el-form-item label="部门名称" prop="departmentName">
          <el-input v-model="ruleForm.departmentName"></el-input>
        </el-form-item>
        <el-form-item label="部门描述" prop="departmentDesc">
          <el-input
            type="textarea"
            rows="4"
            v-model="ruleForm.departmentDesc"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button :style="{ color: buttonTextColor }" @click="handleClose"
        >取 消</el-button
      >
      <el-button
        :style="{ color: buttonTextColor }"
        type="primary"
        @click="submit"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>


<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      ruleForm: {
        departmentDesc: "", //部门描述
        departmentName: "", //部门名称
        sort: 0, //排序
      },
      rules: {
        departmentName: [
          {
            required: true,
            message: "请填写部门名称",
            trigger: "blur",
          },
        ],
        departmentDesc: [
          {
            required: true,
            message: "请填写部门描述",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    ...mapState(["buttonTextColor"]),
  },
  props: ["departVisible"],
  methods: {
    handleClose() {
      this.ruleForm.departmentDesc = ""; //部门描述
      this.ruleForm.departmentName = ""; //部门名称
      this.$refs["ruleForm"].resetFields();
      this.$emit("departClose");
    },
    //确定
    submit() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.request.departmentManagementAdd(this.ruleForm).then((res) => {
            if (res.code==0) {
              this.$message.success("新增成功");
              this.$emit("departmentManagementListAll");
              this.handleClose();
            }
          });
        }
      });
    },
  },
};
</script>


<style scoped>
.from-body {
  padding: 0 60px;
  box-sizing: border-box;
}
</style>