<template>
  <div class="upload">
    <el-upload
      class="upload-image"
      :action="action"
      list-type="picture-card"
      :accept="accept"
      :headers="headers"
      :limit="limit"
      :file-list="fileList"
      :data="qiniuData"
      :before-upload="beforeupload"
      :disabled="fileList.length >= limit || uploaDisabled"
      :on-success="
        (response, file, fileList) => uploadSuccess(response, file, fileList)
      "
    >
      <i slot="default" class="el-icon-plus"></i>
      <div slot="file" slot-scope="{ file }">
        <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
        <span class="el-upload-list__item-actions">
          <!-- 打开图片缩略图 -->
          <span
            class="el-upload-list__item-preview"
            @click="handlePictureCardPreview(file)"
          >
            <i class="el-icon-zoom-in"></i>
          </span>
          <!-- 下载图片 -->
          <span
            v-if="!uploaDisabled"
            class="el-upload-list__item-delete"
            @click="handleDownload(file)"
          >
            <i class="el-icon-download"></i>
          </span>
          <!-- 删除图片 -->
          <span
            v-if="!uploaDisabled"
            class="el-upload-list__item-delete"
            @click="handleRemove(file)"
          >
            <i class="el-icon-delete"></i>
          </span>
        </span>
      </div>
      <div slot="tip" class="el-upload__tip" v-if="limitWidth && limitHeight">
        {{
          "只能上传" +
          accept +
          "图片尺寸" +
          limitWidth +
          "*" +
          limitHeight +
          "px"
        }}
      </div>
    </el-upload>
    <!-- 图片弹窗 -->
    <el-dialog :visible.sync="dialogVisible" :modal="false">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      qiniuData: {},
      domain: null,
      headers: {},
      action: "",
    };
  },
  props: [
    "limit",
    "fileList",
    "type",
    "accept",
    "uploaDisabled",
    "limitWidth",
    "limitHeight",
  ],
  mounted() {
    this.getQiniuToken();
    this.headers = { Authorization: localStorage.getItem("token") };
  },
  methods: {
    //获取七牛token
    getQiniuToken() {
      this.request.qiniuUploadToken().then((res) => {
        this.qiniuData.token = res.data.uptoken;
        this.domain = res.data.domain;
        this.action = res.data.upload;
      });
    },
    //上传成功
    uploadSuccess(res, file, fileList) {
      this.$emit("success", {
        url: this.domain + res.key,
        hash: res.hash,
        name: res.key,
        fileList,
      });
    },
    //删除图片
    handleRemove(file) {
      this.$emit("remove", file.name);
    },
    //打开图片缩略图
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    //下载图片
    handleDownload(file) {
    },
    //  上传图片前处理图片类型和大小
    beforeupload(file) {
      this.qiniuData.key =
        file.name.substring(0, file.name.lastIndexOf(".")) +
        Math.round(Math.random() * 1000) +
        "." +
        file.name.split(".")[file.name.split(".").length - 1];
      if (!this.limitWidth) {
        return true;
      }
      let arr = this.accept.split(",");
      let str = "." + file.name.split(".")[file.name.split(".").length - 1];
      // 文件类型
      const isApk = arr.indexOf(str) != -1;
      if (!isApk) {
        this.$message.error("请上传指定文件格式");
        return false;
      }
      let width = this.limitWidth;
      let height = this.limitHeight;
      const isSize = new Promise(function (resolve, reject) {
        let _URL = window.URL || window.webkitURL;
        let img = new Image();
        img.onload = function () {
          let valid = img.width == width && img.height == height;
          valid ? resolve() : reject();
        };
        img.src = _URL.createObjectURL(file);
      }).then(
        () => {
          return file;
        },
        () => {
          // 图片尺寸670*168px，png或jpg格式，图片大小应低于100KB
          this.$message.warning(
            "图片尺寸670*168px,png或jpg格式,图片大小应低于100KB"
          );
          return Promise.reject();
        }
      );

      return isSize;
    },
  },
};
</script>
<style scoped>
</style>