<!-- 菜单管理 -->
<template>
  <div class="dilu-page">

    <!-- 按钮区域 -->
    <div class="operation-div">
      <el-button :style="{ color: buttonTextColor }" icon="el-icon-plus" type="primary" @click="addView">新增一级菜单</el-button>
      <el-button :style="{ color: buttonTextColor }" icon="el-icon-sort" type="primary" @click="syncTenantMenu" style="margin-left:30px;">同步租户菜单</el-button>
    </div>

    <DiluTable :list="tableData" :tableConfig="tableConfig">
      <el-table-column prop="menuName" label="菜单名称" width="200px"/>
      <el-table-column prop="level" label="菜单类型">
        <template slot-scope="scope">
          <span v-if="scope.row.level == 1">一级菜单</span>
          <span v-else-if="scope.row.level == 2">二级菜单</span>
          <span v-else-if="scope.row.level == 3">三级菜单</span>
          <span v-else>按钮</span>
        </template>
      </el-table-column>
      <el-table-column prop="sort" label="显示顺序" width="100px"/>
      <el-table-column prop="url" label="菜单路由path/按钮标识" width="270px" align="center"/>
      <el-table-column prop="hidden" label="是否隐藏" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.level != 4">{{scope.row.hidden ? "是" : "否"}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="操作" fixed="right" align="center" width="230px">
        <template slot-scope="scope">
          <div class="table-operation" v-if="scope.row.level > 3">
            <el-button @click="editButton(scope.row)" type="text">编辑按钮</el-button>
            <el-button @click="removeButton(scope.row)" type="text">删除按钮</el-button>
          </div>
          <div class="table-operation" v-else>
            <el-button @click="editView(scope.row)" type="text">编辑菜单</el-button>
            <el-button @click="removeView(scope.row)" type="text">删除菜单</el-button>
            <el-button @click="addView(scope.row)" type="text" v-if="scope.row.level < 3">新增子菜单</el-button>
            <el-button @click="addButton(scope.row)" type="text" v-else-if="scope.row.level == 3">新增按钮</el-button>
          </div>
        </template>
      </el-table-column>
    </DiluTable>

    <!-- 新增菜单弹窗 -->
    <AddMenuView :viewVisible="viewVisible" @viewClose="viewClose" :currentMenu="currentMenu" @listTenantMenuButtons="listTenantMenuButtons" :isAdd="isAdd"/>

    <!-- 新增按钮 -->
    <AddMenuButton :buttonVisible="buttonVisible" @buttonClose="buttonClose" :currentMenu="currentMenu" @listTenantMenuButtons="listTenantMenuButtons" :isButtonAdd="isButtonAdd"/>

  </div>
</template>


<script>
import DiluTable from "@/components/public/dilu-table.vue";
import AddMenuView from "./module/addMenuView";
import AddMenuButton from "./module/addMenuButton";
import { mapState } from "vuex";
export default {
  data() {
    return {
      isButtonAdd: true,
      isAdd: true,
      currentMenu: {}, //当前菜单
      buttonVisible: false, //新增按钮
      viewVisible: false, //新增菜单弹窗
      tableConfig: {
        isTree: true,
        isTreeText: "id",
        isPage: false,
      },
      tableData: [],
    };
  },
  components: {
    DiluTable,
    AddMenuView,
    AddMenuButton,
  },
  mounted() {
    this.listTenantMenuButtons();
  },
  computed: {
    ...mapState(["buttonTextColor"]),
  },
  methods: {
    //打开新增菜单弹窗
    addView(e) {
      this.isAdd = true;
      if (e==null) {
        this.currentMenu = {};
      } else {
        this.currentMenu = e;
      }
      this.viewVisible = true;
    },
    //编辑菜单弹窗
    editView(e) {
      this.isAdd = false;
      this.currentMenu = e;
      this.viewVisible = true;
    },
    //关闭新增编辑菜单弹窗
    viewClose() {
      this.viewVisible = false;
    },
    //删除菜单
    removeView(e) {
      this.$confirm("确定删除该菜单？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let str = "?id=" + e.id;
        this.request.tenantMenuDelete(str).then((res) => {
          if (res.code==0) {
            this.$message.success("删除成功");
            this.listTenantMenuButtons();
          }
        });
      });
    },
    //关闭按钮弹窗
    buttonClose() {
      this.buttonVisible = false;
    },
    //新增按钮
    addButton(e) {
      this.isButtonAdd = true;
      this.currentMenu = e;
      this.buttonVisible = true;
    },
    //编辑按钮
    editButton(e) {
      this.isButtonAdd = false;
      this.currentMenu = e;
      this.buttonVisible = true;
    },
    //删除按钮
    removeButton(e) {
      this.$confirm("确定删除该按钮？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let str = "?id=" + Math.abs(e.id);
        this.request.tenantButtonDelete(str).then((res) => {
          if (res.code==0) {
            this.$message.success("删除成功");
            this.listTenantMenuButtons();
          }
        });
      });
    },
    //获取菜单列表
    listTenantMenuButtons() {
      this.request.listTenantMenuButtons().then((res) => {
        this.tableData = res.data;
      });
    },
    //同步租户菜单
    syncTenantMenu() {
      this.request.syncTenantMenu().then((res) => {
        if (res.code==0) {
            this.$message.success("操作成功");
          }
      });
    }
  },
};
</script>

<style scoped>
</style>