<template>
  <el-dialog :title="isAdd ? '新增数据字典' : '编辑数据字典'" :visible.sync="viewVisible" width="650px" :before-close="handleClose" :close-on-click-modal="false" center>
    <div class="from-body">
      <el-form :model="ruleForm" ref="ruleForm" :rules="rules" label-width="auto">
        <el-form-item label="协议名称" prop="protocolName">
          <el-input v-model="ruleForm.protocolName"  :disabled="true"/>
        </el-form-item>
        <el-form-item label="字典名称" prop="name">
          <el-input v-model="ruleForm.name" @input="onInput"/>
        </el-form-item>
        <el-form-item label="中文显示" prop="chinese">
          <el-input v-model="ruleForm.chinese" @input="onInput"/>
        </el-form-item>
        <el-form-item label="英文显示" prop="english">
          <el-input v-model="ruleForm.english" @input="onInput"/>
        </el-form-item>
        <el-form-item label="数据类型" prop="stype">
          <el-select v-model="ruleForm.stype" @input="onInput" style="width:400px;">
            <el-option v-for="(item, index) in typeList" :key="index" :label="item.name" :value="item.name"/>
          </el-select>
        </el-form-item>
        <el-form-item label="数据单位" prop="unit">
          <el-input v-model="ruleForm.unit" @input="onInput"/>
        </el-form-item>
        <el-form-item label="小数位数" prop="digit">
          <el-input v-model="ruleForm.digit" @input="onInput"/>
        </el-form-item>
        <el-form-item label="读写属性" prop="readWrite">
          <el-select v-model="ruleForm.readWrite" placeholder="请选择" @input="onInput" style="width:400px;">
            <el-option label="可读" :value=10 />
            <el-option label="可写" :value=20 />
            <el-option label="可读可写" :value=30 />
          </el-select>
        </el-form-item>
        <el-form-item label="谁能设置" prop="userEdit">
          <el-select v-model="ruleForm.userEdit" placeholder="请选择" @input="onInput" style="width:400px;" clearable>
            <el-option label="用户可设置" :value=10 />
            <el-option label="工程可设置" :value=20 />
            <el-option label="都可以设置" :value=30 />
            <el-option label="都不可设置" :value=40 />
          </el-select>
        </el-form-item>
        <el-form-item label="运行状态" prop="runStatus">
          <el-select v-model="ruleForm.runStatus" placeholder="请选择" @input="onInput" style="width:400px;">
            <el-option label="是" :value=10 />
            <el-option label="否" :value=20 />
          </el-select>
        </el-form-item>
        <el-form-item label="数据范围" prop="fanwei">
          <el-input v-model="ruleForm.fanwei" @input="onInput" placeholder="用~分隔表示连续范围，分号分隔表示可选值"/>
        </el-form-item>
        <el-form-item label="显示顺序" prop="position">
          <el-input v-model="ruleForm.position" @input="onInput"/>
        </el-form-item>
        <el-form-item label="分组名称" prop="groupName">
          <el-input v-model="ruleForm.groupName" @input="onInput"/>
        </el-form-item>
        <el-form-item label="图标路径" prop="iconPth">
          <el-input v-model="ruleForm.iconPth" @input="onInput"/>
        </el-form-item>
        <el-form-item label="首页参数" prop="homeFlag">
          <el-select v-model="ruleForm.homeFlag" placeholder="请选择" @input="onInput" style="width:400px;">
            <el-option label="是" :value=10 />
            <el-option label="不是" :value=20 />
          </el-select>
        </el-form-item>
        <el-form-item label="是否故障" prop="faultFlag">
          <el-select v-model="ruleForm.faultFlag" placeholder="请选择" @input="onInput" style="width:400px;">
            <el-option label="是" :value=10 />
            <el-option label="不是" :value=20 />
          </el-select>
        </el-form-item>
        <el-form-item label="故障等级" prop="faultLevel">
          <el-select v-model="ruleForm.faultLevel" placeholder="请选择" @input="onInput" style="width:400px;" clearable>
            <el-option label="轻微" :value=10 />
            <el-option label="一般" :value=20 />
            <el-option label="严重" :value=30 />
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button :style="{ color: buttonTextColor }" @click="handleClose">取 消</el-button>
      <el-button :style="{ color: buttonTextColor }" type="primary" @click="submit">确 定</el-button>
    </span>
  </el-dialog>
</template>


<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      ruleForm: {
        id: null,
        protocol: null,
        stype: null,
        unit: null,
        name: "",
        chinese: "",
        english: "",
        digit: 0,
        readWrite: 10,
        userEdit: 30,
        runStatus: 10,
        position: 0,
        fanwei: null,
        protocolName: "",
        groupName: "",
        iconPth: "",
        homeFlag: 20,
        faultFlag: 20,
        faultLevel: null
      },
      typeList: [
        {
          "name":"int"
        },
        {
          "name":"double"
        },
        {
          "name":"string"
        }
      ],
      rules: {
        name: [
          {
            required: true,
            message: "请填写数据名称",
            trigger: "blur",
          },
        ],
        stype: [
          {
            required: true,
            message: "请指定数据类型",
            trigger: "blur",
          },
        ]
      },
    };
  },
  watch: {
    viewVisible(newVal) {
      if (newVal) {
        //新增
        if (this.isAdd) {
            this.ruleForm.id = null;
            this.ruleForm.protocol = this.protocol;
            this.ruleForm.stype = null;
            this.ruleForm.unit = null;
            this.ruleForm.name = "";
            this.ruleForm.chinese = "";
            this.ruleForm.english = "";
            this.ruleForm.digit = 0;
            this.ruleForm.readWrite=10;
            this.ruleForm.userEdit=30;
            this.ruleForm.runStatus=10;
            this.ruleForm.position=0;
            this.ruleForm.groupName=null;
            this.ruleForm.fanwei=null;
            this.ruleForm.iconPth=null;
            this.ruleForm.homeFlag=20;
            this.ruleForm.faultFlag=20;
            this.ruleForm.faultLevel=null;
        } else {
          //修改
          this.ruleForm.id = this.currentData.id;
          this.ruleForm.protocol = this.currentData.protocol;
          this.ruleForm.stype = this.currentData.stype;
          this.ruleForm.unit = this.currentData.unit;
          this.ruleForm.name = this.currentData.name;
          this.ruleForm.chinese = this.currentData.chinese;
          this.ruleForm.english = this.currentData.english;
          this.ruleForm.digit = this.currentData.digit;
          this.ruleForm.readWrite = this.currentData.readWrite;
          this.ruleForm.userEdit = this.currentData.userEdit;
          this.ruleForm.runStatus = this.currentData.runStatus;
          this.ruleForm.position = this.currentData.position;
          this.ruleForm.groupName = this.currentData.groupName;
          this.ruleForm.fanwei = this.currentData.fanwei;
          this.ruleForm.iconPth = this.currentData.iconPth;
          this.ruleForm.homeFlag = this.currentData.homeFlag;
          this.ruleForm.faultFlag = this.currentData.faultFlag;
          this.ruleForm.faultLevel = this.currentData.faultLevel;
        }
        this.protocolInfo();
      }
    },
  },
  computed: {
    ...mapState(["buttonTextColor"]),
  },
  props: ["viewVisible","currentData","isAdd","protocol"],
  methods: {
    protocolInfo() {
      var params={
        "id": this.protocol
      };
      this.request.protocolInfo(params).then((res) => {
        if (res.code==0) {
         this.ruleForm.protocolName=res.data['name']+" | "+res.data['codec'];
         this.$forceUpdate();
        }
      });
    },
    onInput() {
      //input输入框无法编辑
      this.$forceUpdate();
    },
    handleClose() {
      this.ruleForm = {
        id: null,
        protocol: null,
        stype: null,
        unit: null,
        name: "",
        chinese: "",
        english: "",
        digit: null,
        readWrite: null,
        userEdit: 30,
        runStatus: 10,
        position: 0,
        groupName: null,
        fanwei: null,
        faultFlag: 10,
        faultLevel: null
      };
      this.$refs["ruleForm"].resetFields();
      this.$emit("viewClose");
    },
    //提交
    submit() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          if (this.isAdd) {
            this.request.dictionaryAdd(this.ruleForm).then((res) => {
              if (res.code==0) {
                this.$message.success("新增成功");
                this.$emit("dictionaryList");
                this.handleClose();
              }
            });
          } else {
            this.request.dictionaryEdit(this.ruleForm).then((res) => {
              if (res.code==0) {
                this.$message.success("编辑成功");
                this.$emit("dictionaryList");
                this.handleClose();
              }
            });
          }
        }
      });
    },
  },
};
</script>


<style scoped>
.from-body {
  padding: 0 60px;
  box-sizing: border-box;
}
</style>