<template>
  <!-- 分享账号 -->
  <el-dialog title="一键分享" :visible.sync="shareVisible" width="450px" :before-close="handleClose" :close-on-click-modal="false" center="">
    <div class="from-body">
      <div class="li">
        <label for="">域名</label>
        <a :href="shareInfo.domain" target="_blank">{{ shareInfo.domain }}</a>
      </div>
      <div class="li">
        <label for="">账号</label>
        <span>{{ shareInfo.username }}</span>
      </div>
      <div class="li">
        <label for="">密码</label>
        <span>{{ shareInfo.password }}</span>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button :style="{ color: buttonTextColor }" @click="handleClose">取消</el-button>
      <el-button :style="{ color: buttonTextColor }" type="primary" @click="copy">复制</el-button>
    </span>
  </el-dialog>
</template>


<script>
import { mapState } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapState(["buttonTextColor"]),
  },
  props: ["shareVisible", "shareInfo"],
  methods: {
    //取消
    handleClose() {
      this.$emit("shareClose");
    },
    //复制
    copy() {
      let cpContent = [
        "登录域名：" + this.shareInfo.domain + "\n",
        "登录账号：" + this.shareInfo.username + "\n",
        "登录密码：" + this.shareInfo.password + "\n",
      ].join("");
      let aux = document.createElement("textarea");
      aux.value = cpContent;
      // aux.setAttribute("value", cpContent);
      document.body.appendChild(aux);
      aux.select();
      document.execCommand("copy");
      document.body.removeChild(aux);
      this.$message({
        message: "复制成功",
        type: "success",
      });
      this.$emit("shareClose");
    },
  },
};
</script>

<style scoped>
.from-body {
  padding: 0 40px;
  box-sizing: border-box;
}
.from-body .li label {
  margin-right: 20px;
}
.from-body .li {
  margin-bottom: 20px;
}
</style>